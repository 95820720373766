import React, { useState } from "react";
import Label from "./Label";

export default function Checkbox({
  id,
  label,
  description,
  values,
  value,
  register,
  errors,
  extraMargin,
  ...rest
}) {
  const [selected, setSelected] = useState(value || "");

  const setValue = (v) => {
    setSelected(v);
  };

  return (
    <div className="mb-3">
      <Label id={id} description={description} extraMargin={extraMargin}>
        {label}
      </Label>
      <div className="flex">
        {values.map((v, i) => {
          const active = selected === v;
          return (
            <span
              key={i}
              className={`bg-white border rounded uppercase md:mx-1 mx-2 cursor-pointer text-sm flex justify-center items-center w-rf-checkbox-width h-rf-checkbox-height focus:shadow-outline ${
                active && "bg-rf-gray-200 text-white"
              } ${errors ? `border-red-500` : `border-rf-gray-100`} ${
                i === 0 ? `ml-0 md:ml-0` : ``
              }`}
              onClick={() => setValue(v)}
              tabIndex="0"
            >
              {v}
            </span>
          );
        })}
      </div>
      <input
        type="hidden"
        id={id}
        ref={register}
        value={selected}
        tabIndex="0"
        {...rest}
      />
      {errors && (
        <span className="text-red-500 text-xs font-semibold">
          {errors.message}
        </span>
      )}
    </div>
  );
}
