import React, { useState } from "react";
import Label from "./Label";

export default function TextAreaWithLabel({
  id,
  label,
  description,
  placeholder,
  extraWrapperClass,
  register,
  errors,
  limit,
  initialCount,
  setError,
  clearErrors,
  ...rest
}) {
  const wrapperClass = `${extraWrapperClass}`;
  const [remaining, setRemaining] = useState(
    initialCount !== 0 ? limit - initialCount : limit
  );

  return (
    <div className={wrapperClass}>
      <Label id={id} tight={true}>
        {label}
      </Label>
      <textarea
        className={`appearance-none block w-full bg-white border rounded py-3 px-4 leading-tight lg:-mb-2 ${
          description && "-mb-1"
        } ${errors ? `border-red-500` : `border-rf-gray-100`}`}
        placeholder={placeholder}
        id={id}
        rows="4"
        ref={register}
        tabIndex="0"
        maxLength={limit}
        onChange={(e) => {
          var r = limit - e.target.value.length;
          setRemaining(r < 0 ? 0 : r);
          if (r < 0) {
            setError(id, {
              type: "manual",
              message: `Character limit is ${limit}`,
            });
          } else {
            clearErrors();
          }
        }}
        {...rest}
      />
      {description && (
        <span className="text-rf-gray-900 text-xxs">{description}</span>
      )}
      <span className="text-rf-gray-900 text-xxs leading-none">
        {remaining} characters left.
      </span>
      {errors && (
        <span className="text-red-500 text-xs font-semibold ml-1">
          {errors.message}
        </span>
      )}
    </div>
  );
}
