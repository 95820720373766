import React, { useState } from "react";

import Label from "./Label";

export default function MultiCheckbox({
  id,
  label,
  description,
  values,
  value,
  register,
  errors,
  single,
  extraMargin,
  ...rest
}) {
  const [selected, setSelected] = useState(value ? value.split(",") : []);

  const setValue = (v) => {
    let selectedValues = [...selected];
    const index = selectedValues.indexOf(v);
    if (index !== -1) {
      selectedValues.splice(index, 1);
      setSelected(selectedValues);
    } else {
      if (v === single || selectedValues.includes(single)) {
        setSelected([v]);
      } else {
        selectedValues.push(v);
        setSelected(selectedValues);
      }
    }
  };

  return (
    <div className="mb-3">
      <Label id={id} description={description} extraMargin={extraMargin}>
        {label}
      </Label>
      <div className="flex">
        {values.map((v, i) => {
          const active = selected.indexOf(v) !== -1;
          return (
            <span
              key={i}
              className={`bg-white border rounded uppercase md:mx-1 mx-2 cursor-pointer text-sm flex justify-center items-center w-rf-checkbox-width h-rf-checkbox-height focus:shadow-outline ${
                active && "bg-rf-gray-200 text-white"
              } ${errors ? `border-red-500` : `border-rf-gray-100`} ${
                i === 0 ? `ml-0 md:ml-0` : ``
              }`}
              onClick={() => setValue(v)}
              tabIndex="0"
            >
              {v}
            </span>
          );
        })}
      </div>
      <input
        type="hidden"
        id={id}
        ref={register}
        value={selected}
        tabIndex="0"
        {...rest}
      />
      {errors && (
        <span className="text-red-500 text-xs font-semibold">
          {errors.message}
        </span>
      )}
    </div>
  );
}
