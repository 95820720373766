import React, { useState } from "react";
import { Range } from "rc-slider";

export function RangeSlider({
  label,
  min,
  max,
  maxValue,
  signSymbol,
  minLabel,
  maxLabel,
  name,
  setFilter,
  values = [],
  ...rest
}) {
  const [selectedMin, setSelectedMin] = useState(min);
  const [selectedMax, setSelectedMax] = useState(max);

  const handleValueChange = (min, max) => {
    setSelectedMin(min);
    setSelectedMax(max);
    if (typeof setFilter === "function") {
      let maxFilterValue = max === maxValue ? null : max;
      setFilter(name, min, maxFilterValue);
    }
  };

  const [filterMin, filterMax] = [...values];

  return (
    <div className="mx-4 mb-2">
      <span className="block tracking-wide text-rf-gray-700 font-semibold">
        {label}
      </span>
      <Range
        min={min--}
        max={max++}
        defaultValue={[min, max]}
        allowCross={false}
        className="my-3"
        autoFocus={false}
        onChange={(v) => handleValueChange(...v)}
        trackStyle={[{ backgroundColor: "#323232" }]}
        handleStyle={[
          {
            backgroundColor: "white",
            borderColor: "#323232",
            borderRadius: 0,
            boxShadow: `#323232 0px 0px`,
          },
          {
            backgroundColor: "white",
            borderColor: "#323232",
            borderRadius: 0,
            boxShadow: `#323232 0px 0px`,
          },
        ]}
        value={[filterMin, (filterMax === null ? maxValue : filterMax)]}
        {...rest}
      />
      <div className="flex justify-between">
        <span className="text-sm text-rf-gray-700">{signSymbol}{minLabel}</span>
        <span className="text-sm text-rf-gray-700">{`${signSymbol}${selectedMin}-${signSymbol}${(selectedMax === maxValue ? maxLabel : selectedMax)}`}</span>
        <span className="text-sm text-rf-gray-700">{signSymbol}{maxLabel}</span>
      </div>
    </div>
  );
}
