import React from "react";

import { LoadingSpinner } from "../utils/icons";

export default function Loading({ extraClass, ...rest }) {
  return (
    <div className="mx-auto my-6">
      <LoadingSpinner extraClass={extraClass} {...rest} />
    </div>
  );
}
