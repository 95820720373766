import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Layout from "./components/Layout";
import { AuthProvider } from "./utils/hooks/use-auth";
import { ErrorMessageProvider } from "./utils/hooks/use-error-message";

function App() {
  return (
    <Router>
      <ErrorMessageProvider>
        <AuthProvider>
          <Layout></Layout>
        </AuthProvider>
      </ErrorMessageProvider>
    </Router>
  );
}

export default App;
