import React from "react";

export default function ResourcesLinks({ community, ...rest }) {
  const { pages } = community;

  return (
    <div className="flex flex-col pb-4 md:p-0 px-16" {...rest}>
      <h3 className="uppercase text-primary-green font-bold md:text-xl mb-2">
        Resources
      </h3>
      <a href={pages.roommateFaq} className="italic text-rf-gray-700 mb-1" target="_blank" rel="noopener noreferrer">
        Check out Roommate Finder FAQ
      </a>
      <a href={pages.petPolicy} className="italic text-rf-gray-700 mb-1" target="_blank" rel="noopener noreferrer">
        {community.communityName} Pet Policy
      </a>
      <a href={pages.qualificationRequirements} className="italic text-rf-gray-700 mb-1" target="_blank" rel="noopener noreferrer">
        {community.communityName} Renter Qualifications
      </a>
      <a href={pages.termsAndConditions} className="italic text-rf-gray-700 mb-1" target="_blank" rel="noopener noreferrer">
        Roommate Finder Terms and Conditions
      </a>
      <a href={pages.privacyPolicy} className="italic text-rf-gray-700 mb-1" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
    </div>
  );
}
