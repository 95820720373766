import React from "react";

import AnyGenderIconSrc from "../images/icons/any-gender-icon.svg";
import MaleGenderIconSrc from "../images/icons/male-icon.svg";
import FemaleGenderIconSrc from "../images/icons/female-icon.svg";
import NoPetsIconSrc from "../images/icons/no-pets-icon.svg";
import PetsIconSrc from "../images/icons/pets-icon.svg";
import CatsIconSrc from "../images/icons/cats-icon.svg";
import DogsIconSrc from "../images/icons/dogs-icon.svg";
import CalendarIconSrc from "../images/icons/calendar-icon.png";
import FavoriteIconSrc from "../images/icons/star.png";
import FavoriteIconFilledSrc from "../images/icons/star-filled.png";
import QuoteSrc from "../images/icons/quote.png";
import QuoteInvertedSrc from "../images/icons/quote-inverted.png";
import CaretUpSrc from "../images/icons/caret-up.svg";
import CaretDownSrc from "../images/icons/caret-down.svg";
import ClearFiltersSrc from "../images/icons/clear-filters-icon.svg";
import FilterIconSrc from "../images/icons/filter-icon.svg";
import SortIconSrc from "../images/icons/sort-icon.svg";
import EqualHousingIconSrc from "../images/icons/equal-housing-lender-icon.png";

export const ClearFiltersIcon = ({ ...rest }) => {
  return <img src={ClearFiltersSrc} alt="Clear filters" {...rest} />;
};

export const CaretUpIcon = ({ ...rest }) => {
  return <img src={CaretUpSrc} alt="Caret up" {...rest} />;
};

export const CaretDownIcon = ({ ...rest }) => {
  return <img src={CaretDownSrc} alt="Caret down" {...rest} />;
};

export const CaretRightIcon = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...rest}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  );
};

export const NoSmokingIcon = ({ ...rest }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 268 233"
      {...rest}
    >
      <rect
        x="187.63"
        y="103.78"
        width="4.92"
        height="31.3"
        rx="2.22"
        fill="#323232"
      />
      <rect
        x="195.45"
        y="103.78"
        width="4.92"
        height="31.3"
        rx="2.22"
        fill="#323232"
      />
      <path
        d="M194.72,98.05c1.18-1.8,2.4-3.43,3.4-5a10.13,10.13,0,0,0,1.1-2.27,1.34,1.34,0,0,0,.07-.7c0-.12,0-.08-.12-.16l-.22-.18a4.12,4.12,0,0,1-1-1,4.3,4.3,0,0,1-.77-2.54,7.24,7.24,0,0,1,1.16-3.66A12.3,12.3,0,0,1,200.45,80a10.84,10.84,0,0,1,2.69-1.86c-.55,2.14-1.3,4-1.77,5.78a6.55,6.55,0,0,0-.3,2.19.63.63,0,0,0,.11.32l.07.07a5.75,5.75,0,0,1,.92.83,4.13,4.13,0,0,1,1,2.62,5.25,5.25,0,0,1-.55,2.25,9.46,9.46,0,0,1-2.24,2.78A15.16,15.16,0,0,1,194.72,98.05Z"
        fill="#323232"
      />
      <path
        d="M196,70.83c-.73,2.63-1.66,4.93-2.26,7.06a8.21,8.21,0,0,0-.42,2.64c0,.29.1.4.13.36a0,0,0,0,0,0,0s0,0,0,0l.35.31a4,4,0,0,1,.4.42,4.94,4.94,0,0,1,1,1.91,6.62,6.62,0,0,1,.22,1.67,9.38,9.38,0,0,1-.41,2.55,14.94,14.94,0,0,1-1.86,3.8A17.58,17.58,0,0,1,187.44,97a56.43,56.43,0,0,1,1.45-7.42,31.58,31.58,0,0,0,.62-3.15,4.39,4.39,0,0,0,0-1.11c0-.27-.11-.18.12.11a.38.38,0,0,0,.09.09l.05,0,0,0-.06-.06c-.23-.2-.44-.39-.65-.61a5.73,5.73,0,0,1-1-1.59,6.14,6.14,0,0,1-.38-3.25,7.8,7.8,0,0,1,.77-2.43,10.92,10.92,0,0,1,1.15-1.89A15,15,0,0,1,196,70.83Z"
        fill="#323232"
      />
      <path
        d="M73.49,48.44,191.42,177,73.49,48.44A88.74,88.74,0,1,0,135,23.62,88.46,88.46,0,0,0,73.49,48.44Z"
        fill="none"
        stroke="#323232"
        stroke-miterlimit="10"
        stroke-width="14"
      />
      <path
        d="M141,135l-74.08.87a3,3,0,0,1-3-3V108a3,3,0,0,1,3-3H114"
        fill="#323232"
      />
      <path
        d="M136,104h45.73a3,3,0,0,1,3,3v24.89a3,3,0,0,1-3,3L165,135"
        fill="#323232"
      />
    </svg>
  );
};

export const AnyGenderIcon = ({ ...rest }) => {
  return <img src={AnyGenderIconSrc} alt="Any Gender" {...rest} />;
};

export const MaleGenderIcon = ({ ...rest }) => {
  return <img src={MaleGenderIconSrc} alt="Male Gender" {...rest} />;
};

export const FemaleGenderIcon = ({ ...rest }) => {
  return <img src={FemaleGenderIconSrc} alt="Female Gender" {...rest} />;
};

export const NoPetsIcon = ({ ...rest }) => (
  <img src={NoPetsIconSrc} alt="No Pets" {...rest} />
);

export const PetsIcon = ({ ...rest }) => (
  <img src={PetsIconSrc} alt="Pets" {...rest} />
);

export const DogsIcon = ({ ...rest }) => {
  return <img src={DogsIconSrc} alt="Dogs" {...rest} />;
};

export const CatsIcon = ({ ...rest }) => {
  return <img src={CatsIconSrc} alt="Cats" {...rest} />;
};

export const CalendarIcon = ({ ...rest }) => (
  <img src={CalendarIconSrc} alt="Calendar" {...rest} />
);

export const FavoriteIcon = ({ favorited, ...rest }) => {
  return (
    <img
      src={favorited ? FavoriteIconFilledSrc : FavoriteIconSrc}
      alt="Favorite"
      {...rest}
    />
  );
};

export const QuoteIcon = ({ ...rest }) => {
  return <img src={QuoteSrc} alt="Quote" {...rest} />;
};

export const QuoteInvertedIcon = ({ ...rest }) => {
  return <img src={QuoteInvertedSrc} alt="Quote" {...rest} />;
};

export const SmokingIcon = ({ ...rest }) => {
  return (
    <svg viewBox="0 0 268 233" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g fill="#323232">
        <rect height="32.29" rx="2.99" width="121.51" x="63.52" y="103.99" />
        <rect height="32.29" rx="2.54" width="5.08" x="188.02" y="103.99" />
        <rect height="32.29" rx="2.54" width="5.08" x="196.1" y="103.99" />
        <path d="m195.34 98.07c1.23-1.83 2.5-3.5 3.54-5.16a9.92 9.92 0 0 0 1.12-2.34 1.6 1.6 0 0 0 .08-.76c0-.13-.06-.1-.14-.2a1.63 1.63 0 0 0 -.24-.19 4.2 4.2 0 0 1 -1.81-3.59 7.41 7.41 0 0 1 1.19-3.76 12.38 12.38 0 0 1 2.16-2.63 11.5 11.5 0 0 1 2.75-2c-.59 2.19-1.38 4.13-1.87 6a6.64 6.64 0 0 0 -.33 2.28.79.79 0 0 0 .12.36.37.37 0 0 1 .09.09 6 6 0 0 1 1 .83 4.16 4.16 0 0 1 1 2.66 5.15 5.15 0 0 1 -.6 2.34 9.57 9.57 0 0 1 -2.29 2.87 15.94 15.94 0 0 1 -5.77 3.2z" />
        <path d="m196.7 70c-.78 2.69-1.75 5-2.39 7.24a8.82 8.82 0 0 0 -.46 2.76c0 .31.11.44.14.42s0 0 0 0l.36.31a5.6 5.6 0 0 1 .41.43 5.11 5.11 0 0 1 1 1.93 6.4 6.4 0 0 1 .22 1.7 9.71 9.71 0 0 1 -.41 2.6 15.14 15.14 0 0 1 -1.91 3.91 18.05 18.05 0 0 1 -5.83 5.7 63.64 63.64 0 0 1 1.56-7.63 31 31 0 0 0 .67-3.25 4.48 4.48 0 0 0 .05-1.17c0-.29-.12-.22.1.06a.3.3 0 0 0 .08.08h.05l-.07-.06c-.22-.2-.45-.41-.66-.63a5.81 5.81 0 0 1 -1-1.6 6.31 6.31 0 0 1 -.39-3.32 8.57 8.57 0 0 1 .78-2.48 12.63 12.63 0 0 1 1.17-1.94 15.58 15.58 0 0 1 6.53-5.06z" />
      </g>
    </svg>
  );
};

export const LoadingSpinner = ({ extraClass, ...rest }) => {
  return (
    <svg
      className={`animate-spin ${extraClass}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export const ExclamationIcon = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...rest}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

export const PlusIcon = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-6 h-6 ml-2"
      {...rest}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

export const FilterIcon = ({ ...rest }) => {
  return <img src={FilterIconSrc} alt="Filter" {...rest} />;
};

export const SortIcon = ({ ...rest }) => {
  return <img src={SortIconSrc} alt="Filter" {...rest} />;
};

export const EqualHousingIcon = ({ ...rest }) => {
  return (
    <img
      src={EqualHousingIconSrc}
      alt="Equal Housing Opportunity Provider"
      {...rest}
    />
  );
};
