import React from "react";
import { useAuth } from "../utils/hooks/use-auth";
import cookies from "js-cookie";

import { useError } from "../utils/hooks/use-error-message";
import { ExclamationIcon } from "../utils/icons";

export default function Error({ children }) {
  const error = useError();
  const auth = useAuth();

  cookies.remove("redirect");

  return (
    <main>
      <div className="flex items-center justify-center">
        <ExclamationIcon className="text-primary-red mr-2 w-16" />
        <h1 className="font-bold md:text-3xl my-5 text-2xl text-center uppercase">
          Something went wrong.
        </h1>
      </div>
      {error && (
        <>
          <p className="text-center">{error.message}</p>
          {auth.user && (
            <span
              className="text-primary-green text-base font-bold cursor-pointer underline flex justify-center mt-1"
              onClick={() => auth.signout()}
            >
              Sign Out
            </span>
          )}
        </>
      )}
    </main>
  );
}
