import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import Loading from "../../../components/Loading";
import { validateEmail } from "../../../services/user-service";
import { ExclamationIcon } from "../../../utils/icons";

export default function EmailVerification() {
  const { hash } = useParams();
  const history = useHistory();

  const [validated, setValidated] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    async function tryValidateEmail() {
      const emailResponse = await validateEmail(hash);
      if (emailResponse.status) {
        setValidated(emailResponse.status === 200);
      } else {
        // .response object is available when there's an error
        const { response } = emailResponse;
        setError(response.data.message);
      }
    }

    tryValidateEmail();
  }, [history, hash]);

  if (error) {
    return (
      <main>
        <div className="flex items-center justify-center">
          <ExclamationIcon className="text-primary-red mr-2 w-16" />
          <h1 className="font-bold md:text-3xl my-5 text-2xl text-center uppercase">
            There's been an error with the validation
          </h1>
        </div>
        <p className="text-center">
          {error} Please go to your{" "}
          <Link
            to="/profile"
            className="underline cursor-pointer font-bold text-primary-green"
          >
            Profile
          </Link>{" "}
          and click the link to re-send the confirmation email for validation.
        </p>
      </main>
    );
  }

  if (validated) {
    return (
      <main>
        <div className="flex items-center justify-center">
          <ExclamationIcon className="text-primary-green mr-2 w-12" />
          <h1 className="font-bold md:text-3xl my-5 text-2xl text-center uppercase">
            Your email was successfully validated.
          </h1>
        </div>
        <p className="text-center">
          You can now search for{" "}
          <Link to="/" className="underline cursor-pointer font-bold">
            Available Roommates
          </Link>{" "}
          or{" "}
          <Link
            to="/profile"
            className="underline cursor-pointer font-bold text-primary-green"
          >
            Update Your Profile Page.
          </Link>
        </p>
      </main>
    );
  }

  return (
    <main>
      <h1 className="font-bold md:text-3xl my-5 text-2xl text-center uppercase">
        Your email is being validated.
      </h1>
      <Loading extraClass="h-32 text-primary-green w-full" />
    </main>
  );
}
