import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { RoommateCard } from "../components/RoommateCard";
import ReportMisconductModal from "../components/modals/ReportMisconductModal";
import { getContacted } from "../services/roommate-service";
import { sortValues } from "../utils/consts";
import Button from "../components/Button";
import { PlusIcon } from "../utils/icons";
import SelectWithLabel from "../components/forms/SelectWithLabel";
import MobileSort from "../components/MobileSort";
import { useError } from "../utils/hooks/use-error-message";

const size = 18;

export default function Contacted({ children }) {
  const [isReportMisconductModalOpen, setReportMisconductModalOpen] =
    useState(false);

  const [total, setTotal] = useState();
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("MoveInDate");
  const [loading, setLoading] = useState(false);
  const error = useError();

  async function updateSorting(value) {
    setItems([]);
    setOrder(value);
    if (page !== 1) {
      setPage(1);
    }
  }

  useEffect(() => {
    async function loadContacted() {
      setLoading(true);
      const response = await getContacted(page, size, order);
      if (response.data) {
        const contacted = response.data.data;
        setItems((i) => [...i, ...contacted.items]);
        setTotal(contacted.total);
      } else {
        const { data } = response.response;
        error.setMessage(data.message);
      }

      setLoading(false);
    }

    loadContacted();
  }, [page, order, error]);

  const loadMore = () => {
    setPage(page + 1);
  };

  return (
    <>
      <Helmet>
        <title>Roommates You Have Contacted | Roommate Finder</title>
      </Helmet>
      <main>
        <h1 className="font-bold md:text-3xl my-5 text-2xl text-center uppercase">
          {items.length} Contacted Roommates
        </h1>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col max-w-6xl md:mx-auto w-full">
            <div className="hidden md:flex justify-between px-4 mb-2">
              <div className="flex items-center">
                <span className="text-rf-gray-700 text-sm mr-2">Sort By:</span>
                <SelectWithLabel
                  name="sort"
                  values={sortValues}
                  value={sortValues[0]}
                  onChange={(e) => updateSorting(e.target.value)}
                />
              </div>
              <div className="flex items-center">
                <span
                  className="italic text-primary-green text-sm cursor-pointer"
                  onClick={() => setReportMisconductModalOpen(true)}
                >
                  Report Misconduct
                </span>
              </div>
            </div>
            <div className="flex md:hidden justify-center px-4 mb-2 z-10">
              <MobileSort
                sortValues={sortValues}
                onSortingChange={(value) => updateSorting(value)}
              />
            </div>
            <div className="flex items-end justify-end px-4 lg:hidden">
              <span
                className="italic text-primary-green text-sm cursor-pointer"
                onClick={() => setReportMisconductModalOpen(true)}
              >
                Report Misconduct
              </span>
            </div>
            <div className="flex flex-col md:flex-row md:flex-wrap">
              {items &&
                items.map((r, i) => {
                  const favorite = r.isFavorite;
                  const contacted = r.isContacted;
                  return (
                    <RoommateCard
                      favorite={favorite}
                      contacted={contacted}
                      roommate={r}
                      key={r.id}
                    />
                  );
                })}
            </div>
            {total > items.length && (
              <Button
                extraClass="border-2 border-solid border-primary-green py-4 font-bold my-4 inline-flex justify-center items-center hover:bg-white hover:text-primary-green hover:border-primary-green bg-primary-green text-white"
                type="submit"
                loading={loading}
                onClick={loadMore}
              >
                Load More
                <PlusIcon />
              </Button>
            )}
          </div>
        </div>

        {isReportMisconductModalOpen && (
          <ReportMisconductModal
            isOpen={isReportMisconductModalOpen}
            closeModal={() => setReportMisconductModalOpen(false)}
          />
        )}
      </main>
    </>
  );
}
