import React, { useState } from "react";

import { ExclamationIcon, LoadingSpinner } from "../utils/icons";
import { sendEmailValidation } from "../services/user-service";

export default function ProfileBanner({ profile, saved }) {
  const [loading, setLoading] = useState(false);
  async function handleEmailValidation() {
    setLoading(true);
    await sendEmailValidation();
    setLoading(false);
  }

  if (profile && !profile.completed) {
    return (
      <div
        className="text-rf-gray-900 px-2 md:px-0 py-3 mb-4 md:w-3/4 md:ml-auto"
        role="alert"
      >
        <div className="flex items-center">
          <div className="py-1">
            <ExclamationIcon className="text-primary-green mr-2 w-12" />
          </div>
          <div>
            <p className="font-bold text-primary-green">
              Please complete your profile
            </p>
            <p className="text-sm">
              In order to search for roommates, you need to complete your
              profile.
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (profile && !profile.emailConfirmed) {
    return (
      <div
        className="text-rf-gray-900 py-3 mb-4 md:w-3/4 md:ml-auto"
        role="alert"
      >
        <div className="flex items-center">
          <div className="py-1">
            {loading ? (
              <LoadingSpinner
                extraClass={`w-12 h-12 mr-2 text-primary-green`}
              />
            ) : (
              <ExclamationIcon className="text-primary-green mr-2 w-12" />
            )}
          </div>
          <div>
            <p className="font-bold text-primary-green">
              Please validate your email
            </p>
            <p className="text-sm">
              In order to search roommates you need to validate your email
              first.{" "}
              {profile.emailConfirmationSent && (
                <span className="font-bold">
                  Please check your email.{" "}
                  <span
                    className={`underline text-primary-green ${
                      !loading && "cursor-pointer"
                    }`}
                    onClick={handleEmailValidation}
                  >
                    (Re-Send confirmation request.)
                  </span>
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (saved) {
    return (
      <div
        className="text-rf-gray-900 py-3 mb-4 md:w-3/4 md:ml-auto"
        role="alert"
      >
        <div className="flex items-center">
          <div className="py-1">
            <ExclamationIcon className="text-primary-green mr-2 w-12" />
          </div>
          <div>
            <p className="font-bold text-primary-green">Profile saved</p>
          </div>
        </div>
      </div>
    );
  }

  return null;
}
