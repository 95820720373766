import React, { useState } from "react";
import Label from "./Label";

export default function RadioButtons({ id, label, values, value, register, errors, ...rest }) {
  const [selected, setSelected] = useState(value);

  return (
    <div className="mb-3">
      <Label id={id}>{label}</Label>
      {values.map((v, i) => {
        return (
          <div className="flex mb-1" key={i}>
            <input
              type="radio"
              className="pt-1 self-center"
              value={v.value}
              checked={selected === v.value}
              onChange={() => setSelected(v.value)}
              ref={register}
              id={v.label}
              tabIndex="0"
              {...rest}
            />
            <label htmlFor={v.label} className="md:text-sm ml-1 self-center text-rf-gray-900 text-xs">
              {v.label}
            </label>
          </div>
        );
      })}
      {errors && <span className="text-red-500 text-xs font-semibold">{errors.message}</span>}
    </div>
  );
}
