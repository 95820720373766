import React from "react";
import { Link } from "react-router-dom";

import { ExclamationIcon, CaretRightIcon } from "../../utils/icons";

function ProfileDeleted() {
  return (
    <main>
      <div className="flex items-center justify-center">
        <ExclamationIcon className="text-primary-green mr-2 w-12" />
        <h1 className="font-bold md:text-3xl my-5 text-2xl text-center uppercase">
          Your profile has been successfully deleted
        </h1>
      </div>
      <p className="text-center flex justify-center">
        Please setup a new profile if you wish to search and connect with
        potential roommates.
        <Link
          to="/"
          className="font-bold flex items-center ml-2 leading-tight text-primary-green"
        >
          Return to homepage
          <CaretRightIcon className="text-xs h-4 w-4 self-end" />
        </Link>
      </p>
    </main>
  );
}

export default ProfileDeleted;
