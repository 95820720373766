import { API_BASE_URL } from "../utils/consts";
import apiCall from "./api-service";

const getUser = async () => {
  return await apiCall({
    endpoint: "/api/user",
    method: "GET",
  });
};

const createUser = async (community) => {
  return await apiCall({
    endpoint: "/api/user",
    method: "POST",
    data: { propertyPrimaryId: community },
  });
};

const deleteUser = async () => {
  return await apiCall({ endpoint: "/api/user", method: "DELETE" });
};

const updateUser = async (user) => {
  return await apiCall({ endpoint: "/api/user", method: "PUT", data: user });
};

const uploadImage = async (file) => {
  const headers = { "Content-Type": "multipart/form-data" };
  const image = file[0];
  const formData = new FormData();
  formData.append("file", image);

  return await apiCall({
    endpoint: "/api/user/photo",
    method: "POST",
    data: formData,
    headers: headers,
  });
};

const uploadImageFromCropped = async (file) => {
  const headers = { "Content-Type": "multipart/form-data" };
  const image = file;
  const formData = new FormData();
  formData.append("photo", image);

  return await apiCall({
    endpoint: "/api/user/photo",
    method: "POST",
    data: formData,
    headers: headers,
  });
};

const sendEmailValidation = async () => {
  return await apiCall({
    endpoint: "/api/user/sendEmailConfirmation",
    method: "POST",
  });
};

const validateEmail = async (hash) => {
  return await apiCall({
    endpoint: `/api/verification/email/${hash}`,
    method: "POST",
  });
};

const reportMissconduct = async (message) => {
  return await apiCall({
    endpoint: "/api/user/submitMisconductReport",
    method: "POST",
    data: {
      message: message,
    },
  });
};

const mapUserToProfile = (user) => {
  const photo = user.photoUrl;
  const rent =
    user.monthlyRentBudget !== undefined &&
    (user.monthlyRentBudget.from || user.monthlyRentBudget.to)
      ? `${user.monthlyRentBudget?.from}-${user.monthlyRentBudget?.to}`
      : "";

  let profile = {
    firstName: user.firstName,
    email: user.email,
    photoUrl:
      photo !== undefined
        ? photo.startsWith("http")
          ? photo
          : `${API_BASE_URL}${photo}`
        : photo,
    overview: user.overview,
    birthdate: user.birthdate,
    gender:
      user.genderType === "Unknown"
        ? "Prefer Not To Say"
        : user.genderType || "",
    monthlyRent: rent,
    pets:
      user.petOwnerTypes && user.petOwnerTypes.length
        ? user.petOwnerTypes.join(",")
        : "",
    moveInDate: user.moveInDate,
    currentLivesAtCommunity:
      user.isCurrentlyLiveAtCommunity !== undefined
        ? user.isCurrentlyLiveAtCommunity
          ? "Yes"
          : "No"
        : "",
    smoker: user.isSmoking !== undefined ? (user.isSmoking ? "Yes" : "No") : "",
    isVisibleForAllUsers: user.isVisibleForAllUsers
      ? user.isVisibleForAllUsers.toString()
      : "true",
    community: user.community,
    completed: user.isProfileCompleted,
    emailConfirmed: user.emailConfirmed,
    leaseLength: user.leaseLength === 0 ? "0" : user.leaseLength || "",
    emailConfirmationSent: user.emailConfirmationSent
  };

  if (user.roommatePreference) {
    profile.petsPreference =
      user.roommatePreference.petOwnerTypes &&
      user.roommatePreference.petOwnerTypes.length
        ? user.roommatePreference.petOwnerTypes.join(",")
        : "";
    profile.genderPreference =
      user.roommatePreference.genderType === "Unknown"
        ? "Any"
        : user.roommatePreference.genderType;
    profile.smokingPreference =
      user.roommatePreference.isSmoking !== undefined
        ? user.roommatePreference.isSmoking
          ? "Yes"
          : "No"
        : "";
    profile.openToLiveNearby =
      user.roommatePreference.isOpenToLivingAtNearbyAreaCommunity !== undefined
        ? user.roommatePreference.isOpenToLivingAtNearbyAreaCommunity
          ? "Yes"
          : "No"
        : "";
  }

  return profile;
};

const mapProfileToUser = (profile) => {
  const monthlyRentBudget = profile.monthlyRent.split(`-`);
  const from = monthlyRentBudget[0];
  const to = monthlyRentBudget[1];

  let user = {
    firstName: profile.firstName,
    email: profile.email,
    photoUrl: profile.photoUrl,
    overview: profile.overview,
    birthdate: profile.birthdate,
    genderType:
      profile.gender === "Prefer Not To Say" ? "Unknown" : profile.gender,
    monthlyRentBudget: {
      from: from === "undefined" ? null : parseInt(from),
      to: to === "undefined" ? null : parseInt(to),
    },
    petOwnerTypes: profile.pets.split(","),
    moveInDate: profile.moveInDate,
    isCurrentlyLiveAtCommunity: profile.currentLivesAtCommunity === "Yes",
    isSmoking: profile.smoker === "Yes",
    isVisibleForAllUsers: profile.isVisibleForAllUsers === "true",
    community: profile.community,
    emailConfirmed: profile.emailConfirmed,
    isProfileCompleted: profile.completed,
    leaseLength: parseInt(profile.leaseLength),
    roommatePreference: {
      petOwnerTypes: profile.petsPreference.split(","),
      genderType:
        profile.genderPreference === "Any"
          ? "Unknown"
          : profile.genderPreference,
      isSmoking: profile.smokingPreference === "Yes",
      isOpenToLivingAtNearbyAreaCommunity: profile.openToLiveNearby === "Yes",
    },
  };

  return user;
};

export {
  getUser,
  createUser,
  deleteUser,
  updateUser,
  sendEmailValidation,
  validateEmail,
  uploadImage,
  uploadImageFromCropped,
  reportMissconduct,
  mapUserToProfile,
  mapProfileToUser,
};
