import React, { useEffect } from "react";

import Loading from "../../components/Loading";
import { useAuth } from "../../utils/hooks/use-auth";
import { ExclamationIcon } from "../../utils/icons";

function SignoutOidc() {
  const auth = useAuth();

  useEffect(() => {
    async function signoutAsync() {
      await auth.signoutCallback();
    }

    signoutAsync();
  }, [auth]);

  if (!auth.profile) {
    return (
      <main>
        <div className="flex items-center justify-center">
          <ExclamationIcon className="text-primary-green mr-2 w-12" />
          <h1 className="font-bold md:text-3xl my-5 text-2xl text-center uppercase">
            Your logout was successful
          </h1>
        </div>
        <p className="text-center">
          Please{" "}
          <span
            className="font-bold text-primary-green underline cursor-pointer"
            onClick={() => auth.signin()}
          >
            Log In
          </span>{" "}
          again to view roommates
        </p>
      </main>
    );
  }

  return <Loading extraClass="h-32 text-primary-green w-full" />;
}

export default SignoutOidc;
