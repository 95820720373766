import React from "react";
import { Menu, Transition } from "@headlessui/react";

import { SortIcon } from "../utils/icons";

export default function MobileSort({ sortValues, onSortingChange }) {
  return (
    <Menu>
      {({ open }) => (
        <div className={open ? "relative" : ""}>
          <span className="bg-white text-base py-2 px-6 rounded uppercase flex border border-rf-gray-700">
            <Menu.Button className="flex flex-row items-center text-rf-gray-700 uppercase">
              <SortIcon className="mr-2 h-4" /> Sort
            </Menu.Button>
          </span>
          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white border divide-y divide-gray-100 rounded-md shadow-lg outline-none flex flex-col border-rf-gray-700">
              {sortValues.map((opt) => {
                return (
                  <Menu.Item key={opt.value}>
                    {({ active }) => (
                      <span
                        className={`${
                          active ? "bg-gray-100" : ""
                        } justify-between w-full px-4 py-2 text-sm leading-5 text-left text-rf-gray-700 rounded`}
                        onClick={() => onSortingChange(opt.value)}
                      >
                        {opt.label}
                      </span>
                    )}
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
}
