import React, { useEffect, useState } from "react";
import axios from "axios";

import { API_BASE_URL, AUTH_BASE_URL } from "../../utils/consts";
import Loading from "../../components/Loading";

export default function Status({ children }) {
  const [authStatus, setAuthStatus] = useState(null);
  const [apiStatus, setApiStatus] = useState(null);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/health/live`)
      .then((response) => {
        setApiStatus(response.data.status);
      })
      .catch(() => {
        setApiStatus("");
      });

    axios
      .get(`${AUTH_BASE_URL}/health/live`)
      .then((response) => {
        setAuthStatus(response.data.status);
      })
      .catch(() => {
        setAuthStatus("");
      });
  }, []);

  return (
    <div className="flex flex-col">
      <h1 className="font-bold md:text-3xl my-5 text-2xl text-center uppercase">
        Current Status
      </h1>

      {authStatus === null && apiStatus === null ? (
        <Loading extraClass="h-32 text-primary-green w-full" />
      ) : (
        <div className="flex flex-col md:flex-row px-4 md:justify-center">
          <div className="rounded bg-rf-gray flex flex-row p-4 justify-center items-center mb-2 md:mr-4 md:px-8">
            <div class={`rounded-full h-12 w-12 bg-green-500`}></div>
            <span className="ml-2">Auth Server</span>
          </div>
          <div className="rounded bg-rf-gray flex flex-row p-4 justify-center items-center mb-2 md:mr-4 md:px-8">
            <div
              class={`rounded-full h-12 w-12 ${
                apiStatus === "Healthy" ? "bg-green-500" : "bg-red-500"
              }`}
            ></div>
            <span className="ml-2">API Server</span>
          </div>
        </div>
      )}
    </div>
  );
}
