import React from "react";
import { Helmet } from "react-helmet";

import Routes from "../utils/routes";
import Footer from "./Footer";
import Hero from "./Hero";
import Navigation from "./Navigation";

export default function Layout({ children }) {
  return (
    <>
      <Routes>
        <Helmet>
          <title>Roommate Finder by UDR</title>
        </Helmet>
        <Hero />
        <Navigation />
        {children}
      </Routes>
      <Footer />
    </>
  );
}
