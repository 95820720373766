import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../utils/hooks/use-auth";
import { useError } from "../utils/hooks/use-error-message";

export default function Navigation() {
  const auth = useAuth();
  const error = useError();
  const [invisible, setInvisible] = useState("");
  const [myProfileInvisible, setMyProfileInvisible] = useState("");
  const activeClassName = `bg-white border-white border-b-2 -my-1 text-rf-gray-600`;

  useEffect(() => {
    setInvisible(
      !auth.profile?.completed ||
        !auth.profile?.emailConfirmed ||
        auth.profile?.isVisibleForAllUsers === "false" ||
        auth.profile?.isDisabled ||
        error.message
        ? "invisible"
        : ""
    );

    setMyProfileInvisible(
      !auth.profile || auth.profile?.isDisabled || error.message
        ? "invisible"
        : ""
    );
  }, [auth.profile, error.message]);

  return (
    <ul className="bg-primary-green border-b border-rf-gray-900 border-t flex items-center justify-between md:justify-center w-full md:-mt-1 md:z-10">
      <li className="md:mr-1">
        <NavLink
          activeClassName={activeClassName}
          to="/"
          className={`inline-block md:px-8 px-1 py-2 text-sm md:text-lg text-white uppercase ${invisible}`}
          exact
        >
          Roommates
        </NavLink>
      </li>
      <li className="md:mr-1">
        <NavLink
          activeClassName={activeClassName}
          to="/favorites"
          className={`inline-block md:px-8 px-1 py-2 text-sm md:text-lg text-white uppercase ${invisible}`}
        >
          Favorites
        </NavLink>
      </li>
      <li className="md:mr-1">
        <NavLink
          activeClassName={activeClassName}
          to="/contacted"
          className={`inline-block md:px-8 px-1 py-2 text-sm md:text-lg text-white uppercase ${invisible}`}
        >
          Contacted
        </NavLink>
      </li>
      <li>
        <NavLink
          activeClassName={activeClassName}
          to="/profile"
          className={`inline-block md:px-8 px-1 py-2 text-sm md:text-lg text-white uppercase ${myProfileInvisible}`}
        >
          My Profile
        </NavLink>
      </li>
    </ul>
  );
}
