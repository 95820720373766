import React, { useEffect } from "react";
import cookies from "js-cookie";

import { useAuth } from "../../utils/hooks/use-auth";
import { useRouter } from "../../utils/hooks/use-router";
import Loading from "../../components/Loading";
import { useError } from "../../utils/hooks/use-error-message";
import Error from "../error";

export default function SigninOidc() {
  const auth = useAuth();
  const router = useRouter();
  const error = useError();

  useEffect(() => {
    async function signinAsync() {
      if (!auth.user) {
        await auth.signInCallback();
      }

      if (auth.profile) {
        const url =
          auth.profile.completed && auth.profile.emailConfirmed
            ? cookies.get("redirect") || "/"
            : "/profile";
        router.push(url);
      }
    }

    signinAsync();
  }, [auth, router]);

  if (error.message) {
    return <Error />;
  }

  return <Loading extraClass="h-32 text-primary-green w-full" />;
}
