export const API_BASE_URL = window.env.API_BASE_URL;
export const IMAGES_FS_PATH = window.env.IMAGES_FS_PATH;
export const GTM_CONFIG = {
  gtmId: window.env.GTM_ID,
  auth: window.env.GTM_AUTH,
  preview: window.env.GTM_PREVIEW,
};

export const AUTH_BASE_URL = window.env.AUTH_BASE_URL;
export const AUTH_CLIENT_ID = window.env.AUTH_CLIENT_ID;
export const BASE_URL = window.env.BASE_URL;

export const ERRORS = {
  AUTH_SERVER_ERROR: "Auth server not available",
  USER_SERVICE_ERROR: "User service not available",
  NO_COMMUNITY: "Please contact Roommate Finder support at roommatefinder@udr.com for assistance.",
  PROFILE_DISABLED: "Your profile was disabled. Please contact support.",
};

export const rentValues = [
  { value: `undefined-500`, label: "$500" },
  { value: `500-1000`, label: "$500 - $1000" },
  { value: `1000-1500`, label: "$1000 - $1500" },
  { value: `1500-2000`, label: "$1500 - $2000" },
  { value: `2000-2500`, label: "$2000 - $2500" },
  { value: `2500-3000`, label: "$2500 - $3000" },
  { value: `3000-3500`, label: "$3000 - $3500" },
  { value: `3500-4000`, label: "$3500 - $4000" },
  { value: `4000-undefined`, label: "$4000+" },
];

export const leaseValues = [
  { value: 6, label: "6 Months" },
  { value: 9, label: "9 Months" },
  { value: 12, label: "12 Months" },
  { value: 0, label: "Any" },
];

export const genderValues = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Prefer Not To Say", label: "Prefer Not To Say" },
];

export const petValues = ["Dog", "Cat", "None"];

export const yesNoValues = ["Yes", "No"];

export const genderPreferences = ["Any", "Male", "Female"];

export const profileValues = [
  { value: "true", label: "Visible To All Users" },
  { value: "false", label: "Hidden To All Users" },
];

export const sortValues = [
  { value: "MoveInDate", label: "Target Move-in Date" },
  { value: "Age", label: "Age" },
  { value: "PreferredLeaseLength", label: "Lease Preference" },
];
