import React, { useEffect, useState, useRef } from "react";
import "rc-slider/assets/index.css";

import Button from "../components/Button";

import { RoommateCard } from "../components/RoommateCard";
import { RangeSlider } from "../components/RangeSlider";
import ContactModal from "../components/modals/ContactModal";
import ReportMisconductModal from "../components/modals/ReportMisconductModal";
import ResourcesLinks from "../components/ResourcesLinks";
import SelectWithLabel from "../components/forms/SelectWithLabel";
import MobileSort from "../components/MobileSort";

import { getRoommates } from "../services/roommate-service";

import { sortValues } from "../utils/consts";
import { ClearFiltersIcon, FilterIcon, PlusIcon } from "../utils/icons";
import { useError } from "../utils/hooks/use-error-message";
import { useAuth } from "../utils/hooks/use-auth";

const size = 18;

export default function RoommatesListing() {
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const [currentRoommate, setCurrentRoommate] = useState();
  const [isReportMisconductModalOpen, setReportMisconductModalOpen] =
    useState(false);
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(sortValues[0].value);
  const [isSticky, setSticky] = useState(false);
  const stickyRef = useRef(null);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [loadIntialPage, setLoadInitialPage] = useState(false);
  const error = useError();
  const auth = useAuth();

  const { profile } = auth;

  const handleContactClick = (roommate) => {
    setCurrentRoommate(roommate);
    setContactModalOpen(true);
  };

  const handleContactSuccess = () => {
    setItems(
      items.map((i) => {
        if (i.id === currentRoommate.id) {
          i.isContacted = true;
        }

        return i;
      })
    );
  };

  const handleContactModalClose = () => {
    setContactModalOpen(false);
  };

  const toggleArrayFilter = async (key, value) => {
    const current = filters[key] || [];
    if (current.includes(value)) {
      setFilters({ ...filters, [key]: current.filter((v) => v !== value) });
    } else {
      setFilters({ ...filters, [key]: [...current, value] });
    }
  };

  const toggleFilter = async (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const toggleRangeFilter = async (key, min, max) => {
    setFilters({ ...filters, [key]: { from: min, to: max } });
  };

  async function loadRoommates() {
    setLoading(true);
    const response = await getRoommates(page, size, filters, order);

    if (response.data) {
      const data = response.data.data;
      setItems((i) => [...i, ...data.items]);
      setTotal(data.total);
    } else {
      const { data } = response.response;
      error.setMessage(data.message);
    }

    setLoading(false);
  }

  async function updateFilters() {
    setPage(1);
    setItems([]);
    await loadRoommates();

    if (filterModalOpen) {
      handleFilterModal();
    }
  }

  async function clearFilters() {
    setFilters({});
    setItems([]);
    setPage(1);
    setLoadInitialPage(true);
  }

  async function updateSorting(value) {
    setItems([]);
    setOrder(value);
    if (page !== 1) {
      setPage(1);
    }
  }

  useEffect(() => {
    loadRoommates();
  }, [page, order]);

  useEffect(() => {
    setLoadInitialPage(false);
    if (loadIntialPage) {
      loadRoommates();
    }
  }, [loadIntialPage]);

  const loadMore = () => {
    setPage(page + 1);
  };

  const handleScroll = () => {
    if (stickyRef.current) {
      setSticky(stickyRef.current.getBoundingClientRect().top <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const handleFilterModal = () => {
    document.body.style.overflow = filterModalOpen ? "" : "hidden";
    setFilterModalOpen(!filterModalOpen);
  };

  return (
    <main>
      <h1
        className="font-bold md:text-3xl my-5 text-2xl text-center uppercase"
        ref={stickyRef}
      >
        {items.length ? `Showing ${items.length} of ${total} roommates` : ""}
      </h1>
      <div className="flex flex-col md:flex-row">
        <div
          className={`modal-overlay w-full h-full top-0 left-0 bg-black opacity-50 z-10 md:z-0 ${
            filterModalOpen ? `fixed` : `hidden`
          }`}
          onClick={handleFilterModal}
        ></div>
        <div
          className={`lg:flex lg:flex-col lg:w-1/4 z-20 lg:z-0 m-6 lg:m-0 overflow-y-auto ${
            filterModalOpen ? `fixed inset-0` : `hidden`
          } ${isSticky ? `md:sticky top-0` : ``}`}
        >
          <div
            className={`bg-white rounded-r border border-rf-gray-700 flex flex-col lg:mr-4 xl:mr-24`}
          >
            <div className="flex flex-row justify-between">
              <h3 className="uppercase text-2xl bold font-bold text-rf-gray-700 my-2 mx-4">
                Filter
              </h3>
              {Object.keys(filters).length !== 0 && (
                <span
                  className="flex flex-row items-center uppercase mr-4 cursor-pointer"
                  onClick={clearFilters}
                >
                  <ClearFiltersIcon className="mr-2" /> Clear
                </span>
              )}
            </div>

            <RangeSlider
              label="Preferred age:"
              min={18}
              max={60}
              maxValue={60}
              signSymbol=""
              minLabel="18 years"
              maxLabel="60+"
              name="ageRange"
              setFilter={toggleRangeFilter}
              values={
                filters.ageRange
                  ? [filters.ageRange.from, filters.ageRange.to]
                  : [18, null]
              }
            />
            <RangeSlider
              label="Monthly rent:"
              min={500}
              max={4000}
              maxValue={4000}
              signSymbol="$"
              minLabel="500"
              maxLabel="4000+"
              name="monthlyRentRange"
              setFilter={toggleRangeFilter}
              step={100}
              values={
                filters.monthlyRentRange
                  ? [filters.monthlyRentRange.from, filters.monthlyRentRange.to]
                  : [500, null]
              }
            />
            <div className="mx-4 mb-2">
              <span className="block tracking-wide text-rf-gray-700 font-semibold mb-2">
                I prefer to live with:
              </span>
              <div className="flex">
                <label className="text-rf-gray-700 text-sm mr-2">
                  <input
                    className="mr-1 leading-tight"
                    type="radio"
                    name="preferredGender"
                    onChange={() => toggleFilter("preferredGender", "Male")}
                    checked={filters.preferredGender === "Male"}
                  />
                  <span className="text-sm">Male</span>
                </label>
                <label className="text-rf-gray-700 text-sm mr-2">
                  <input
                    className="mr-1 leading-tight"
                    type="radio"
                    name="preferredGender"
                    onChange={() => toggleFilter("preferredGender", "Female")}
                    checked={filters.preferredGender === "Female"}
                  />
                  <span className="text-sm">Female</span>
                </label>
                <label className="text-rf-gray-700 text-sm mr-2">
                  <input
                    className="mr-1 leading-tight"
                    type="radio"
                    name="preferredGender"
                    onChange={() => toggleFilter("preferredGender", "Unknown")}
                    checked={filters.preferredGender === "Unknown"}
                  />
                  <span className="text-sm">Any</span>
                </label>
              </div>
            </div>
            <div className="mx-4 mb-2">
              <span className="block tracking-wide text-rf-gray-700 font-semibold mb-2">
                Are pets okay?
              </span>
              <div className="flex">
                <label className="text-rf-gray-700 text-sm mr-2">
                  <input
                    className="mr-1 leading-tight"
                    type="checkbox"
                    onChange={() => toggleArrayFilter("preferredPets", "Dog")}
                    checked={
                      filters.preferredPets &&
                      filters.preferredPets.length &&
                      filters.preferredPets.includes("Dog")
                    }
                  />
                  <span className="text-sm">Dogs</span>
                </label>
                <label className="text-rf-gray-700 text-sm mr-2">
                  <input
                    className="mr-1 leading-tight"
                    type="checkbox"
                    onChange={() => toggleArrayFilter("preferredPets", "Cat")}
                    checked={
                      filters.preferredPets &&
                      filters.preferredPets.length &&
                      filters.preferredPets.includes("Cat")
                    }
                  />
                  <span className="text-sm">Cats</span>
                </label>
                <label className="text-rf-gray-700 text-sm mr-2">
                  <input
                    className="mr-1 leading-tight"
                    type="checkbox"
                    onChange={() => toggleArrayFilter("preferredPets", "None")}
                    checked={
                      filters.preferredPets &&
                      filters.preferredPets.length &&
                      filters.preferredPets.includes("None")
                    }
                  />
                  <span className="text-sm">None</span>
                </label>
              </div>
            </div>
            <div className="mx-4 mb-2">
              <span className="block tracking-wide text-rf-gray-700 font-semibold mb-2">
                Is smoking okay?
              </span>
              <div className="flex">
                <label className="text-rf-gray-700 text-sm mr-2">
                  <input
                    className="mr-1 leading-tight"
                    type="radio"
                    name="smoking"
                    checked={filters.isSmoking}
                    onChange={() => toggleFilter("isSmoking", true)}
                  />
                  <span className="text-sm">Yes</span>
                </label>
                <label className="text-rf-gray-700 text-sm mr-2">
                  <input
                    className="mr-1 leading-tight"
                    type="radio"
                    name="smoking"
                    checked={filters.isSmoking === false}
                    onChange={() => toggleFilter("isSmoking", false)}
                  />
                  <span className="text-sm">No</span>
                </label>
              </div>
            </div>
            <div className="mx-4 mb-2">
              <span className="block tracking-wide text-rf-gray-700 font-semibold mb-2">
                Only show profiles with photos:
              </span>
              <div className="flex">
                <label className="text-rf-gray-700 text-sm mr-2">
                  <input
                    className="mr-1 leading-tight"
                    type="radio"
                    name="photo-only"
                    checked={filters.withPhotoOnly}
                    onChange={() => toggleFilter("withPhotoOnly", true)}
                  />
                  <span className="text-sm">Yes</span>
                </label>
                <label className="text-rf-gray-700 text-sm mr-2">
                  <input
                    className="mr-1 leading-tight"
                    type="radio"
                    name="photo-only"
                    checked={filters.withPhotoOnly === false}
                    onChange={() => toggleFilter("withPhotoOnly", false)}
                  />
                  <span className="text-sm">No</span>
                </label>
              </div>
            </div>
            <div className="flex">
              <Button
                extraClass="m-2 font-bold px-16 md:px-10 lg:px-16 text-sm text-white bg-primary-green"
                onClick={updateFilters}
              >
                Apply
              </Button>
            </div>
          </div>
          {profile?.community?.pages && (
            <ResourcesLinks
              community={profile.community}
              className="hidden lg:flex flex-col pb-4 lg:p-0 mx-4 my-2"
            />
          )}
        </div>
        <div className="flex flex-col lg:w-3/4 w-full xl:mr-56">
          <div className="justify-between px-4 mb-2 hidden lg:flex">
            <div className="flex flex-row items-center">
              <span className="text-rf-gray-700 text-sm mr-2">Sort By:</span>
              <SelectWithLabel
                values={sortValues}
                value={sortValues[0]}
                onChange={(e) => updateSorting(e.target.value)}
              />
            </div>
            <div className="flex items-center">
              <span
                className="italic text-primary-green text-sm cursor-pointer"
                onClick={() => setReportMisconductModalOpen(true)}
              >
                Report Misconduct
              </span>
            </div>
          </div>
          <div className="flex lg:hidden justify-center px-4 mb-2 z-10">
            <Button
              extraClass="border border-rf-gray-700 items-center mr-2 text-white text-rf-gray-700"
              onClick={handleFilterModal}
            >
              <FilterIcon className="mr-2 h-4" /> Filter
            </Button>
            <MobileSort
              sortValues={sortValues}
              onSortingChange={(value) => updateSorting(value)}
            />
          </div>
          <div className="flex items-end justify-end px-4 lg:hidden">
            <span
              className="italic text-primary-green text-sm cursor-pointer"
              onClick={() => setReportMisconductModalOpen(true)}
            >
              Report Misconduct
            </span>
          </div>
          <div className="flex flex-col mb-4">
            <div className="flex flex-col md:flex-row md:flex-wrap">
              {items &&
                items.map((r, i) => {
                  const favorite = r.isFavorite;
                  const contacted = r.isContacted;
                  return (
                    <RoommateCard
                      favorite={favorite}
                      contacted={contacted}
                      roommate={r}
                      key={r.id}
                      onContactClick={() => handleContactClick(r)}
                    />
                  );
                })}
            </div>
            {total > items.length && (
              <Button
                extraClass="border-2 border-solid border-primary-green py-4 font-bold my-4 inline-flex justify-center items-center hover:bg-white hover:text-primary-green hover:border-primary-green bg-primary-green text-white"
                type="submit"
                loading={loading}
                onClick={loadMore}
              >
                {loading ? (
                  "Loading"
                ) : (
                  <>
                    Load More <PlusIcon />
                  </>
                )}
              </Button>
            )}
            {profile?.community?.pages && (
              <ResourcesLinks
                community={profile.community}
                className="flex lg:hidden flex-col pb-4 lg:p-0 mx-4 my-2"
              />
            )}
          </div>
        </div>
      </div>

      {isContactModalOpen && (
        <ContactModal
          isOpen={isContactModalOpen}
          closeModal={handleContactModalClose}
          roommate={currentRoommate}
          onContactSuccess={handleContactSuccess}
        />
      )}
      {isReportMisconductModalOpen && (
        <ReportMisconductModal
          isOpen={isReportMisconductModalOpen}
          closeModal={() => setReportMisconductModalOpen(false)}
        />
      )}
    </main>
  );
}
