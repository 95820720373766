import React, { useState } from "react";
import { differenceInYears, format } from "date-fns";
import { useForm } from "react-hook-form/dist/index.ie11";

import Button from "../Button";
import Modal from "../Modal";
import { CalendarIcon } from "../../utils/icons";
import toDate from "../../utils/to-date";
import RoommatePhotoSrc from "../../images/profile-large.png";
import { useLockBodyScroll } from "../../utils/hooks/use-lock-body-scroll";
import {
  GenderPreferenceIcon,
  PetsPreferenceIcon,
  SmokingPreferenceIcon,
} from "../CardIcons";
import { contactRoommate } from "../../services/roommate-service";
import { API_BASE_URL } from "../../utils/consts";
import TextAreaWithLabel from "../forms/TextAreaWithLabel";

export default function ContactModal({
  isOpen,
  closeModal,
  roommate,
  onContactSuccess,
  children,
  ...rest
}) {
  const { register, handleSubmit, setError, clearErrors, errors } = useForm();

  const {
    id,
    firstName,
    birthdate,
    genderType,
    moveInDate,
    roommatePreference,
    photoUrl,
  } = roommate;

  const [loading, setLoading] = useState(false);
  const [contacted, setContacted] = useState(false);

  const age = differenceInYears(new Date(), toDate(birthdate));
  const targetMovinDate = format(toDate(moveInDate), "MMM yyyy");
  const gender = genderType === "Unknown" ? "" : `${genderType}`;

  useLockBodyScroll();

  const onSubmit = async (data) => {
    setLoading(true);
    const response = await contactRoommate(id, data.message);
    setContacted(response.status === 200);

    if (onContactSuccess && typeof onContactSuccess === "function") {
      onContactSuccess();
    }

    setLoading(false);
  };

  const photo = photoUrl ? `${API_BASE_URL}${photoUrl}` : RoommatePhotoSrc;

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} {...rest}>
      <div className="modal-container bg-white w-11/12 md:w-full md:max-w-xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
        <div className="p-2">
          <div className="flex justify-between">
            {!contacted && (
              <span className="uppercase text-sm text-primary-green font-bold">
                TO:
              </span>
            )}
            <svg
              className="fill-current text-primary-green font-bold cursor-pointer ml-auto"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              onClick={closeModal}
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </div>
          <div className="modal-content text-left px-2 md:px-4 pb-2 flex-col">
            {contacted ? (
              <div className="flex flex-col">
                <h2 className="uppercase text-primary-green text-xl text-center">
                  Thank <span className="font-bold">you!</span>
                </h2>
                <p className="mb-2 md:mb-4 text-center">
                  The message to <span className="font-bold">{firstName}</span>{" "}
                  is being sent on your behalf.
                </p>
                <div className="flex justify-center">
                  <Button
                    extraClass="mt-2 md:mt-0 justify-center bg-primary-green text-sm text-white"
                    onClick={closeModal}
                  >
                    Close
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <div className="flex self-center items-center justify-around mb-1">
                  <div className="flex flex-col md:flex-row items-center md:w-1/2">
                    <img
                      src={photo}
                      alt={firstName}
                      className="h-16 w-16 md:w-32 md:h-32 rounded-full border-2 border-rf-gray-700"
                    />
                    <div className="p-1 md:p-2">
                      <span className="block uppercase text-xs md:text-base text-primary-green font-bold">
                        {firstName}
                      </span>
                      <span className="block text-xs md:text-base text-rf-gray-600">
                        {age}
                        {gender && `, ${gender}`}
                      </span>
                    </div>
                  </div>
                  <div className="flex self-center items-center md:w-1/2">
                    <div className="flex flex-col flex-1 relative justify-items-center items-center">
                      <PetsPreferenceIcon
                        pets={roommatePreference.petOwnerTypes}
                      />
                    </div>
                    <div className="flex flex-col flex-1 relative justify-items-center items-center">
                      <SmokingPreferenceIcon
                        smoking={roommatePreference.isSmoking}
                      />
                    </div>
                    <div className="flex flex-col flex-1 relative justify-items-center items-center">
                      <GenderPreferenceIcon
                        gender={roommatePreference.genderType}
                      />
                    </div>
                    <div className="flex flex-col flex-1 relative justify-items-center items-center">
                      <CalendarIcon className="px-2 md:px-4 w-full h-8 md:h-10 mb-2" />
                      <span className="self-end text-center text-rf-gray-700 text-xxs w-full">
                        {targetMovinDate}
                      </span>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex-col">
                    <p className="text-primary-green">
                      Let us send an email to introduce yourself to{" "}
                      <span className="font-bold">{firstName}</span> on your
                      behalf!
                    </p>

                    <TextAreaWithLabel
                      rows={5}
                      className={`appearance-none w-full bg-white border rounded p-3 leading-tight text-rf-gray-700 ${
                        errors.message ? `border-red-500` : `border-rf-gray-100`
                      } ${contacted ? `bg-rf-gray` : ``}`}
                      placeholder={`Introduce yourself! Example "I'm looking for a roommate and thought you may want to discuss..."`}
                      name="message"
                      register={register({ required: true, maxLength: 500 })}
                      disabled={contacted}
                      limit={500}
                      initialCount={0}
                      setError={setError}
                      clearErrors={clearErrors}
                    />
                  </div>
                  <div className="flex flex-col justify-between">
                    <span className="text-xs w-full">
                      Your email address and profile will be shared with{" "}
                      <span className="font-bold">{firstName}</span>. All
                      communication you exchange with{" "}
                      <span className="font-bold">{firstName}</span> from this
                      initial email will be private to you and{" "}
                      <span className="font-bold">{firstName}</span> and be
                      conducted outside of this Roommate finder website.
                    </span>
                    <Button
                      extraClass={`self-end mt-2 md:mt-0 text-sm text-white ${
                        contacted && "cursor-default"
                      } ${contacted ? `bg-rf-gray-100` : `bg-primary-green`}`}
                      loading={loading}
                      type="submit"
                      disabled={contacted}
                    >
                      {contacted
                        ? "Contacted"
                        : loading
                        ? "Sending Message"
                        : "Send Message"}
                    </Button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
