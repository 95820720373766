import React from "react";

export default function Label({
  id,
  description,
  descriptionBlock,
  children,
  tight,
  extraMargin,
  ...rest
}) {
  const classes = `block tracking-wide text-rf-gray-900 text-sm font-semibold ${tight ? `leading-tight` : ``} ${extraMargin}`
  return (
    <label
      className={classes}
      htmlFor={id}
      {...rest}
    >
      {children}
      {description && (
        <span
          className={`text-xxs font-normal ${
            descriptionBlock ? `block` : `ml-1`
          }`}
        >
          {description}
        </span>
      )}
    </label>
  );
}
