import React from "react";
import { CaretRightIcon, EqualHousingIcon } from "../utils/icons";

export default function Footer() {
  return (
    <div className="bg-rf-gray-500 flex flex-col-reverse md:flex-row mt-auto justify-between text-white text-xs font-bold px-4 py-4">
      <div className="flex flex-row">
        <span>© 2021 UDR and Its Affiliates.</span>{" "}
        <a
          href="https://udr.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-rf-gray-300 hover:text-white italic ml-2 text-xs flex flex-row justify-center items-center font-light"
        >
          Privacy Policy<CaretRightIcon className="text-xs h-4 w-4 self-end ml-2" />
        </a>
      </div>
      <div className="flex flex-row mb-2 md:mb-0 items-center">
        <span>Equal Housing Opportunity Provider</span>
        <EqualHousingIcon className="ml-2" />
      </div>
    </div>
  );
}
