import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from "react";
import Oidc, { UserManager } from "oidc-client";
import cookies from "js-cookie";

import { setAuthHeader } from "../axios-headers";
import {
  createUser,
  getUser,
  mapUserToProfile,
} from "../../services/user-service";
import { AUTH_BASE_URL, AUTH_CLIENT_ID, BASE_URL, ERRORS } from "../consts";
import { useRouter } from "./use-router";
import { useError } from "./use-error-message";

const config = {
  authority: AUTH_BASE_URL,
  client_id: AUTH_CLIENT_ID,
  redirect_uri: `${BASE_URL}/auth/signin-oidc`,
  response_type: "code",
  scope: "openid rf.api.user rf.profile",
  post_logout_redirect_uri: `${BASE_URL}/logout-success`,
  loadUserInfo: true,
  automaticSilentRenew: true,
  silent_redirect_uri: `${BASE_URL}/auth/silent-renew-oidc`,
  revokeAccessTokenOnSignout: true,
};

const userManager = new UserManager(config);

if (process.env.NODE_ENV !== "production") {
  Oidc.Log.logger = console;
}

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const router = useRouter();
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const error = useError();

  const signin = useCallback(async () => {
    try {
      const url = router.pathname === "/logout-success" ? "/" : router.pathname;
      cookies.set("redirect", url, { path: "/" });
      return await userManager.signinRedirect();
    } catch (ex) {
      error.setMessage(ERRORS.AUTH_SERVER_ERROR);
    }
  }, [router, error]);

  const signInCallback = async () => {
    const userResponse = await userManager.signinRedirectCallback();
    setUser(userResponse);
    setAuthHeader(userResponse.access_token);
    const response = await getUser();

    if (response.message === "Network Error") {
      error.setMessage(ERRORS.USER_SERVICE_ERROR);
    } else {
      if (response.status && response.status === 200) {
        const data = response.data.data;
        setProfile(mapUserToProfile(data));
      } else {
        const { data, status } = response.response;
        if (status === 404) {
          const communityId = cookies.get("communityId");
          if (communityId !== undefined) {
            const createUserResponse = await createUser(parseInt(communityId));
            setProfile(mapUserToProfile(createUserResponse.data.data));
          } else {
            error.setMessage(ERRORS.NO_COMMUNITY);
          }
        }

        if (status === 422) {
          error.setMessage(data.message);
        }
      }
    }
  };

  const signout = async () => {
    return userManager.signoutRedirect();
  };

  const signoutCallback = async () => {
    await userManager.clearStaleState();
    await userManager.removeUser();
    return userManager.signoutRedirectCallback().then((response) => {
      setUser(false);
      setAuthHeader();
      setProfile(null);
      const redirect = cookies.get("redirect");
      cookies.remove("redirect");
      if(redirect) {
        router.push(redirect);
      }
    });
  };

  const updateProfile = (data) => {
    setProfile(mapUserToProfile(data));
  };

  const updateProfileImage = (image) => {
    setProfile({ ...profile, photoUrl: image });
  };

  const loadCurrentUser = async () => {
    const userResponse = await userManager.getUser();
    setUser(userResponse);
    setAuthHeader(userResponse.access_token);

    const response = await getUser();
    if (response === undefined) {
      error.setMessage(ERRORS.USER_SERVICE_ERROR);
    } else {
      if (response.status && response.status === 200) {
        const data = response.data.data;
        setProfile(mapUserToProfile(data));
      } else {
        const { data, status } = response.response;
        if (status === 404) {
          const communityId = cookies.get("communityId");
          if (communityId !== undefined) {
            const createUserResponse = await createUser(parseInt(communityId));
            setProfile(mapUserToProfile(createUserResponse.data.data));
          }

          error.setMessage(ERRORS.NO_COMMUNITY);
        }

        if (status === 422) {
          error.setMessage(data.message);
        }
      }
    }
  };

  useEffect(() => {
    userManager.events.addAccessTokenExpiring(() => {
      userManager.signinSilentCallback().then((user) => {
        let data = sessionStorage.getItem(
          `oidc.user:${AUTH_BASE_URL}:${AUTH_CLIENT_ID}`
        );

        if (data) {
          data = JSON.parse(data);
          setAuthHeader(data.access_token);
        }
      });
    });

    return userManager.events.removeAccessTokenExpiring(() => {});
  }, []);

  useEffect(() => {
    userManager.events.addUserSignedOut(() => {
      signin();
    });

    return userManager.events.removeUserSignedOut();
  }, [signin]);

  useEffect(() => {
    userManager.events.addSilentRenewError(async () => {
      await signout();
    });

    return userManager.events.removeSilentRenewError(() => {});
  }, []);

  useEffect(() => {
    if (profile && profile.isDisabled) {
      error.setMessage(ERRORS.PROFILE_DISABLED);
    }
  }, [profile, error]);

  return {
    user,
    profile,
    updateProfile,
    updateProfileImage,
    signin,
    signInCallback,
    signout,
    signoutCallback,
    loadCurrentUser,
  };
}
