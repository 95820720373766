import React from "react";

import Label from "./Label";

export default function InputWithLabel({
  id,
  label,
  description,
  placeholder,
  type,
  register,
  errors,
  control,
  disabled = false,
  ...rest
}) {
  return (
    <div className="mb-3">
      <Label id={id} description={description}>
        {label}
      </Label>
      <input
        className={`appearance-none block w-full bg-white border rounded py-2 px-2 md:px-4 leading-tight ${
          errors ? `border-red-500` : `border-rf-gray-100`
        } ${disabled && `bg-rf-gray`}`}
        type={type}
        placeholder={placeholder}
        id={id}
        ref={register}
        disabled={disabled}
        tabIndex="0"
        {...rest}
      />
      {errors && (
        <span className="text-red-500 text-xs font-semibold">
          {errors.message}
        </span>
      )}
    </div>
  );
}
