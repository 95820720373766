import React from "react";
import Label from "./Label";

export default function SelectWithLabel({
  id,
  label,
  values,
  value,
  register,
  errors,
  ...rest
}) {
  return (
    <div className="mb-3">
      {label && <Label id={id}>{label}</Label>}
      <div className="relative">
        <select
          className={`block appearance-none w-full bg-white border rounded py-2 px-4 leading-tight ${
            errors && errors.message ? `border-red-500` : `border-rf-gray-100`
          }`}
          id={id}
          ref={register}
          defaultValue={value}
          {...rest}
          style={{
            appearance: "none",
            WebkitAppearance: "none",
            MozAppearance: "none",
          }}
          tabIndex="0"
        >
          <option disabled value="">
            Please select an option
          </option>
          {values.map((v, i) => {
            return (
              <option key={i} value={v.value}>
                {v.label}
              </option>
            );
          })}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
      {errors && (
        <span className="text-red-500 text-xs font-semibold">
          {errors.message}
        </span>
      )}
    </div>
  );
}
