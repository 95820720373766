import React, { useState } from "react";
import { useForm } from "react-hook-form/dist/index.ie11";
import { reportMissconduct } from "../../services/user-service";

import { useLockBodyScroll } from "../../utils/hooks/use-lock-body-scroll";

import Button from "../Button";
import TextAreaWithLabel from "../forms/TextAreaWithLabel";
import Modal from "../Modal";

export default function ReportMisconductModal({
  isOpen,
  closeModal,
  children,
  ...rest
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();
  const { register, handleSubmit, setError, clearErrors, errors } = useForm();

  useLockBodyScroll();

  const onSubmit = async (data) => {
    setLoading(true);
    const response = await reportMissconduct(data.message);
    setSuccess(response.status === 200);
    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} {...rest}>
      <div className="modal-container bg-white w-11/12 md:w-full md:max-w-xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
        <div className="p-2">
          <div className="flex justify-between">
            {!success && (
              <span className="uppercase text-sm text-primary-green font-bold pl-4">
                Report Misconduct:
              </span>
            )}
            <svg
              className="fill-current text-primary-green font-bold cursor-pointer ml-auto"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              onClick={closeModal}
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </div>
          <div className="modal-content text-left px-4 pb-2 flex-col">
            {success ? (
              <div className="flex flex-col">
                <h2 className="uppercase text-primary-green text-xl text-center">
                  Thank <span className="font-bold">you!</span>
                </h2>
                <p className="mb-2 md:mb-4 text-center">
                  We will review your message.
                </p>
                <div className="flex justify-center">
                  <Button
                    extraClass="mt-2 md:mt-0 justify-center bg-primary-green text-white text-sm"
                    onClick={closeModal}
                  >
                    Close
                  </Button>
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col">
                  <p className="text-rf-gray-700 mb-4">
                    See something inappropriate? Please tell us about it in the
                    comment box below and we'll be sure to check things out.
                  </p>
                  <TextAreaWithLabel
                    rows={5}
                    className={`appearance-none w-full bg-white border rounded p-3 leading-tight text-rf-gray-700 ${
                      errors.message ? `border-red-500` : `border-rf-gray-100`
                    } ${success ? `bg-rf-gray` : ``}`}
                    name="message"
                    register={register({ required: true, maxLength: 500 })}
                    disabled={success}
                    limit={500}
                    initialCount={0}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                </div>
                <div className="flex flex-col justify-between pt-2">
                  <Button
                    loading={loading}
                    extraClass="bg-primary-green ml-auto text-white text-sm"
                  >
                    {loading ? "Sending Message" : "Send Message"}
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
