import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { useLockBodyScroll } from "../../utils/hooks/use-lock-body-scroll";
import Button from "../Button";
import Modal from "../Modal";

import placeholderImage from "../../images/profile-large.png";
import { uploadImageFromCropped } from "../../services/user-service";
import { API_BASE_URL } from "../../utils/consts";
import { useAuth } from "../../utils/hooks/use-auth";

const pixelRatio = window.devicePixelRatio || 1;
const outerHeight = window.outerHeight;

function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}

export default function UploadImageModal({
  isOpen,
  closeModal,
  children,
  ...rest
}) {
  useLockBodyScroll();
  const auth = useAuth();

  const [imageFile, setImageFile] = useState();
  const [imageName, setImageName] = useState();
  const [crop, setCrop] = useState({
    aspect: 1,
    width: 50,
    height: 50,
    unit: "%",
    x: 25,
    y: 25,
  });
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(null);
  const [error, setError] = useState();
  const imageRef = useRef(null);
  const canvasRef = useRef(null);

  const handleInputChange = (e) => {
    const img = e.target.files[0];
    if (img.size > 5242880) {
      setError("Image has to be less than 5MB.");
    } else {
      setImageFile(URL.createObjectURL(img));
      setImageName(img.name);
    }
  };

  const onCropChange = (c) => {
    setCrop(c);
  };

  const onCropComplete = (c) => {
    setCompleted(c);
  };

  const onImageLoaded = useCallback((image) => {
    imageRef.current = image;
  }, []);

  useEffect(() => {
    if (!imageRef.current) {
      return;
    }

    const image = imageRef.current;
    const aspect = 1;
    const width =
      image.width / aspect < image.height * aspect
        ? 100
        : ((image.height * aspect) / image.width) * 100;
    const height =
      image.width / aspect > image.height * aspect
        ? 100
        : (image.width / aspect / image.height) * 100;
    const y = (100 - height) / 2;
    const x = (100 - width) / 2;

    setCrop({
      unit: "%",
      width,
      height,
      x,
      y,
      aspect,
    });
  }, [imageRef]);

  const handleButtonClick = async () => {
    if (!canvasRef.current || !completed) {
      return;
    }

    setLoading(true);

    const canvas = getResizedCanvas(
      canvasRef.current,
      completed.width,
      completed.height
    );

    const img = canvas.toDataURL();
    let arr = img.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const response = await uploadImageFromCropped(
      new File([u8arr], imageName, { type: mime })
    );

    setLoading(false);

    if (response.status === 200) {
      const uri = response.data.data;
      auth.updateProfileImage(`${API_BASE_URL}${uri}`);
      closeModal();
    }
  };

  useEffect(() => {
    if (!completed || !canvasRef.current || !imageRef.current) {
      return;
    }

    const image = imageRef.current;
    const canvas = canvasRef.current;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = completed.width * pixelRatio;
    canvas.height = completed.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      completed.x * scaleX,
      completed.y * scaleY,
      completed.width * scaleX,
      completed.height * scaleY,
      0,
      0,
      completed.width,
      completed.height
    );
  }, [completed]);

  const smallScreen =
    imageRef.current && outerHeight < 750 && imageRef.current.height > 450;
  const modalCss = `modal-container bg-white h-full w-full mx-auto rounded shadow-lg z-50 overflow-y-scroll lg:overflow-y-auto xl:h-auto xl:max-w-xl ${
    smallScreen ? `h-screen` : ``
  }`;
  const containerCss = `p-2 mb-4 ${
    smallScreen ? `overflow-y-scroll h-full` : ``
  }`;

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} {...rest}>
      <div className={modalCss} style={{ maxHeight: "95vh" }}>
        <div className={containerCss}>
          <div className="flex justify-between">
            <span className="uppercase text-sm text-primary-green font-bold">
              UPLOAD PROFILE IMAGE:
            </span>
            <svg
              className="fill-current text-primary-green font-bold cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              onClick={closeModal}
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </div>
          <div className="flex flex-col md:mx-16 items-center">
            <div className="flex flex-col md:flex-row mt-4 mb-2 items-center justify-center">
              <input
                type="file"
                name="image"
                accept="image/*"
                id="image"
                className="hidden"
                onChange={handleInputChange}
              />
              <label
                htmlFor="image"
                className="bg-white text-primary-green rounded border border-rf-gray-900 p-2 cursor-pointer"
              >
                Browse for file
              </label>
              <span className="text-rf-gray-900 text-sm ml-2">{imageName}</span>
            </div>
            {error && (
              <span className="text-red-500 text-xs font-semibold">
                {error}
              </span>
            )}

            <p className="my-2 text-rf-gray-700">
              Move your face into the highlighted area of the circle.
            </p>
            {imageFile && (
              <Button
                extraClass="text-white bg-primary-green"
                onClick={handleButtonClick}
                loading={loading}
              >
                Set Profile Image
              </Button>
            )}

            <ReactCrop
              crop={crop}
              src={imageFile || placeholderImage}
              circularCrop={true}
              onChange={onCropChange}
              onComplete={onCropComplete}
              onImageLoaded={onImageLoaded}
              className="my-4 mx-4"
            />
            <div className="hidden">
              <canvas
                ref={canvasRef}
                style={{
                  width: Math.round(completed?.width ?? 0),
                  height: Math.round(completed?.height ?? 0),
                }}
              ></canvas>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
