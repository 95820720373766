import apiCall from "./api-service";

export const getRoommates = async (page, size, filter, order) => {
  const response = await apiCall({
    endpoint: `/api/roommate/search`,
    data: {
      filter,
      page: {
        pageNumber: page,
        pageSize: size,
        orderings: [{ by: order, ascending: true }],
      },
    },
    method: "POST",
  });

  return response;
};

export const getFavorites = async (page, size, order) => {
  const response = await apiCall({
    endpoint: `/api/roommate/favorites`,
    data: {
      pageNumber: page,
      pageSize: size,
      orderings: [
        {
          by: order,
          ascending: true,
        },
      ],
    },
    method: "POST",
  });

  return response;
};

export const setFavorite = async (user) => {
  const response = await apiCall({
    endpoint: `/api/roommate/${user}/favorite`,
    method: "POST",
  });

  return response;
};

export const removeFavorite = async (user) => {
  const response = await apiCall({
    endpoint: `/api/roommate/${user}/unfavorite`,
    method: "POST",
  });

  return response;
};

export const getContacted = async (page, size, order) => {
  const response = await apiCall({
    endpoint: `/api/roommate/contacted`,
    method: "POST",
    data: {
      pageNumber: page,
      pageSize: size,
      orderings: [
        {
          by: order,
          ascending: true,
        },
      ],
    },
  });

  return response;
};

export const contactRoommate = async (user, message) => {
  const response = await apiCall({
    endpoint: `/api/roommate/${user}/contact`,
    method: "POST",
    data: { message },
  });

  return response;
};
