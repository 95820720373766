import React, { createContext, useContext, useState } from "react";

const ErrorMessageContext = createContext();

export function ErrorMessageProvider({ children }) {
  const errorMessage = useProvideErrorMessage();
  return (
    <ErrorMessageContext.Provider value={errorMessage}>
      {children}
    </ErrorMessageContext.Provider>
  );
}

export function useError() {
  return useContext(ErrorMessageContext);
}

function useProvideErrorMessage() {
  const [message, setMessage] = useState();

  return {
    message,
    setMessage,
  };
}
