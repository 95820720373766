import { useEffect } from "react";
import { useAuth } from "./use-auth.js";

export default function useRequireAuth() {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.user) {
      auth.signin();
    }
  }, [auth]);

  return auth;
}
