import React from "react";
import { Switch, Route } from "react-router-dom";
import cookies from "js-cookie";

import SigninOidc from "../pages/auth/signin-oidc";
import SignoutOidc from "../pages/auth/signout-oidc";
import Contacted from "../pages/contacted";
import Favorites from "../pages/favorites";
import RoommatesListing from "../pages/roommates";
import Profile from "../pages/profile";
import Error from "../pages/error";
import Status from "../pages/admin/status";
import SilentRenew from "../pages/auth/silent-renew-oidc";
import NotFound from "../pages/404";
import EmailVerification from "../pages/auth/email/verification";
import ProfileDeleted from "../pages/auth/profile-deleted";

import useRequireAuth from "../utils/hooks/use-require-auth.js";
import Loading from "../components/Loading";
import { useRouter } from "./hooks/use-router";
import { useError } from "./hooks/use-error-message";

export default function Routes({ children }) {
  return (
    <>
      {children}
      <Switch>
        <PrivateRoute exact path="/" component={RoommatesListing} />
        <PrivateRoute path="/favorites" component={Favorites} />
        <PrivateRoute path="/contacted" component={Contacted} />
        <PrivateRoute path="/profile" component={Profile} />
        <Route path="/email/verification/:hash" component={EmailVerification} />
        <Route path="/auth/signin-oidc" component={SigninOidc} />
        <Route path="/logout-success" component={SignoutOidc} />
        <Route path="/profile-deleted" component={ProfileDeleted} />
        <Route path="/auth/silent-renew-oidc" component={SilentRenew} />
        <Route path="/admin/status" component={Status} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}

function PrivateRoute({ children, ...rest }) {
  const auth = useRequireAuth();
  const router = useRouter();
  const error = useError();

  if (error.message) {
    return <Error />;
  }

  const communityId = router.query.communityId || router.query.communityid;
  if (communityId) {
    cookies.set("communityId", communityId, { path: "/" });
  }

  if (!auth.user && !auth.profile) {
    return <Loading extraClass="h-32 text-primary-green w-full" />;
  }

  return <Route {...rest} />;
}
