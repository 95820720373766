import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../utils/hooks/use-auth.js";
import useOutsideClick from "../utils/hooks/use-outside-click.js";
import logo from "../images/roommate-finder-logo-blur.png";
import logoMobile from "../images/roommate-finder-logo-mobile.png";
import profileSrc from "../images/profile-small.png";
import { Helmet } from "react-helmet";

export default function Hero({ children }) {
  const auth = useAuth();
  const [profileVisible, setProfileVisible] = useState(false);
  const profileRef = useRef();

  const background =
    auth?.profile?.community?.resources?.headerImageUrl ||
    "/images/bg-header-3.jpg";

  useOutsideClick(profileRef, () => {
    if (profileVisible) setProfileVisible(!profileVisible);
  });

  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href="/images/bg-header-3.jpg" />
      </Helmet>
      <div
        className="bg-center flex bg-no-repeat bg-cover"
        style={{ minHeight: `16rem`, backgroundImage: `url(${background})` }}
      >
        <div className="flex items-center justify-center md:justify-between md:my-0 w-full relative bg-logo-blur bg-cover bg-right-top md:bg-none">
          {auth.profile &&
            auth.profile.completed &&
            auth.profile.emailConfirmed &&
            auth.profile.isVisibleForAllUsers === "true" &&
            !auth.profile.isDisabled ? (
            <Link to="/">
              <img
                src={logo}
                alt="Find a Roomie Logo"
                className="h-full hidden md:flex"
              />
              <img
                src={logoMobile}
                alt="Find a Roomie Logo"
                className="w-full px-20 md:hidden"
              />
            </Link>
          ) : (
            <>
              <img
                src={logo}
                alt="Find a Roomie Logo"
                className="h-full hidden md:flex"
              />
              <img
                src={logoMobile}
                alt="Find a Roomie Logo"
                className="w-full px-20 md:hidden"
              />
            </>
          )}

          {auth.user && auth.profile && !auth.profile.isDisabled && (
            <div className="mx-4 md:mx-8 my-4 md:flex md:flex-col absolute top-0 right-0">
              <img
                src={auth?.profile?.photoUrl || profileSrc}
                alt="Profile"
                className="ml-auto cursor-pointer w-16 md:w-20 h-16 md:h-20 rounded-full border border-rf-gray-700"
                onClick={() => setProfileVisible(!profileVisible)}
              />
              <div
                ref={profileRef}
                className={`flex flex-col bg-white border rounded mt-2 ${!profileVisible && "hidden"
                  }`}
              >
                <div className="flex m-2">
                  <div className="md:pl-2 mr-4">
                    <img
                      src={auth?.profile?.photoUrl || profileSrc}
                      alt="Profile"
                      className="mb-2 md:mb-4 mt-2 w-full md:w-24 md:h-24 rounded-full border border-rf-gray-700"
                    />
                    <Link
                      to="/profile"
                      className="text-rf-gray-700 text-sm"
                      onClick={() => setProfileVisible(false)}
                    >
                      Update Profile
                    </Link>
                  </div>
                  <div className="pr-4">
                    <span className="block font-bold text-primary-green text-xl">
                      {auth.profile
                        ? auth.profile.firstName
                        : auth.user.profile.given_name}
                    </span>
                    <span className="text-rf-gray-700 text-sm">
                      {auth.profile
                        ? auth.profile.email
                        : auth.user.profile.email}
                    </span>
                  </div>
                </div>
                <div className="border-t border-rf-gray-700 px-2 md:px-4 py-2 text-left">
                  <span
                    className="text-rf-gray-700 text-sm cursor-pointer"
                    onClick={() => auth.signout()}
                  >
                    Sign Out
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        {children}
      </div>
    </>
  );
}
