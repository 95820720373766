import React, { useState } from "react";
import { differenceInYears, differenceInDays, format } from "date-fns";

import toDate from "../utils/to-date";

import Button from "./Button";
import {
  CalendarIcon,
  CaretDownIcon,
  CaretUpIcon,
  FavoriteIcon,
  QuoteIcon,
} from "../utils/icons";
import RoommatePhotoSrc from "../images/profile-large.png";
import {
  GenderPreferenceIcon,
  PetsPreferenceIcon,
  SmokingPreferenceIcon,
} from "./CardIcons";
import { removeFavorite, setFavorite } from "../services/roommate-service";
import { API_BASE_URL } from "../utils/consts";

export function RoommateCard({
  favorite,
  contacted,
  onContactClick,
  roommate,
  onFavoriteChange,
}) {
  const {
    id,
    firstName,
    photoUrl,
    petOwnerTypes,
    birthdate,
    genderType,
    overview,
    lastActiveDateTime,
    moveInDate,
    community,
    isCurrentlyLiveAtCommunity,
    monthlyRentBudget,
    roommatePreference,
    leaseLength,
  } = roommate;

  const age = differenceInYears(new Date(), toDate(birthdate));
  const activeDays = differenceInDays(new Date(), new Date(lastActiveDateTime));
  const targetMovinDate = format(toDate(moveInDate), "MMM yyyy");

  const [favorited, setFavorited] = useState(favorite);
  const [fullTextVisible, setFullTextVisible] = useState(false);

  const gender = genderType === "Unknown" ? "" : `${genderType}`;
  let budget;
  if (!monthlyRentBudget.from) {
    budget = `$${monthlyRentBudget.to}`;
  } else if (!monthlyRentBudget.to) {
    budget = `$${monthlyRentBudget.from}+`;
  } else {
    budget = `$${monthlyRentBudget.from} - $${monthlyRentBudget.to}`;
  }

  const lease = leaseLength ? ` | ${leaseLength} month lease` : ``;

  async function toggleFavorite() {
    const response = favorited
      ? await removeFavorite(id)
      : await setFavorite(id);
    if (response.status === 200) {
      setFavorited(!favorited);
    }

    if (onFavoriteChange && typeof onFavoriteChange === "function") {
      onFavoriteChange(id, !favorited);
    }
  }

  const photo =
    photoUrl !== undefined
      ? photoUrl.startsWith("http")
        ? photoUrl
        : `${API_BASE_URL}${photoUrl}`
      : RoommatePhotoSrc;

  return (
    <div className="px-4 py-2 md:px-2 xl:px-4 w-full md:w-1/2 lg:w-1/3">
      <div
        className={`bg-rf-gray border border-rf-gray-700 rounded-t ${
          !isCurrentlyLiveAtCommunity && `rounded-b`
        }`}
      >
        <div className="flex items-center relative leading-tight md:leading-normal mb-4 md:mb-0">
          <FavoriteIcon
            favorited={favorited}
            className="md:absolute cursor-pointer mx-1 my-2 md:m-2 top-0 w-6"
            onClick={toggleFavorite}
          />
          <div className="md:hidden mt-2 self-start flex">
            <span className="uppercase text-base text-rf-gray-400 font-bold mr-2">
              {firstName}
            </span>
            <span className="text-rf-gray-600 text-xs">{`${age}${
              gender && `, ${gender.charAt(0)}`
            }${
              !petOwnerTypes.includes("None")
                ? `, ${petOwnerTypes.join(" & ")} owner`
                : ``
            }`}</span>
          </div>
          <span className="text-xxs md:text-xs text-rf-gray-700 ml-auto mr-1 self-start mt-2">{`Active ${activeDays} days ago`}</span>
        </div>
        <div className="flex md:ml-3 md:pt-4">
          <div className="p-2">
            <img
              src={photo}
              alt="Profile"
              className="-mt-4 border border-rf-gray-700 h-24 rounded-full w-24"
            />
          </div>
          <div className="w-2/3 md:w-1/2 hidden md:flex md:flex-col">
            <span className="block uppercase text-lg text-rf-gray-400 font-bold leading-none mb-1">
              {firstName}
            </span>
            <p className="text-rf-gray-600 leading-none">
              <span className="block font-old">{`${age}${
                gender && `, ${gender}`
              }`}</span>
              {!petOwnerTypes.includes("None") &&
                petOwnerTypes.map((pet, index) => {
                  return (
                    <span className="block" key={index}>
                      {pet} owner
                    </span>
                  );
                })}
            </p>
          </div>
          <div className="flex flex-col md:hidden w-2/3">
            <div className="flex w-full">
              <div className="flex flex-col flex-1 relative justify-items-center items-center">
                <PetsPreferenceIcon pets={roommatePreference.petOwnerTypes} />
              </div>
              <div className="flex flex-col flex-1 relative justify-items-center items-center">
                <SmokingPreferenceIcon smoking={roommatePreference.isSmoking} />
              </div>
              <div className="flex flex-col flex-1 relative justify-items-center items-center">
                <GenderPreferenceIcon gender={roommatePreference.genderType} />
              </div>
              <div className="flex flex-col flex-1 relative justify-items-center items-center">
                <CalendarIcon className="w-8 h-8 md:w-10 md:h-10 mb-2" />
                <span className="self-end text-center text-rf-gray-700 text-xxs w-full">
                  {targetMovinDate}
                </span>
              </div>
            </div>
            <div className="flex flex-col w-full my-3 text-sm items-center leading-none text-center">
              <span className="mb-2">
                {roommatePreference.isOpenToLivingAtNearbyAreaCommunity
                  ? `Live anywhere in ${community.areaName}`
                  : `Prefer to live at ${community.communityName}`}
              </span>
              <span>
                {budget}
                {lease}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-start">
          <QuoteIcon className="h-4 md:h-3 ml-2" />
          <div className={`mx-2 my-2 text-rf-gray-700 text-sm relative`}>
            <p
              className={`overflow-hidden ${
                !fullTextVisible ? `h-16` : `h-auto`
              }`}
            >
              {overview}
            </p>
            {overview.length > 150 && (
              <div
                className={`flex bottom-0 right-0 bg-rf-gray font-bold cursor-pointer justify-end ${
                  fullTextVisible ? `justify-end` : `absolute`
                }`}
                onClick={() => setFullTextVisible(!fullTextVisible)}
              >
                {fullTextVisible ? (
                  <>
                    Show Less <CaretUpIcon className="ml-1" />
                  </>
                ) : (
                  <>
                    ... Show More <CaretDownIcon className="ml-1" />
                  </>
                )}
              </div>
            )}
          </div>
          <QuoteIcon className="h-4 md:h-3 mr-2 transform rotate-180" />
        </div>
        <div className="md:flex m-3 hidden pb-3">
          <div className="flex flex-col flex-1 relative justify-items-center items-center">
            <PetsPreferenceIcon pets={roommatePreference.petOwnerTypes} />
          </div>
          <div className="flex flex-col flex-1 relative justify-items-center items-center">
            <SmokingPreferenceIcon smoking={roommatePreference.isSmoking} />
          </div>
          <div className="flex flex-col flex-1 relative justify-items-center items-center">
            <GenderPreferenceIcon gender={roommatePreference.genderType} />
          </div>
          <div className="flex flex-col flex-1 relative justify-items-center items-center">
            <CalendarIcon className="w-8 h-8 md:w-10 md:h-10 mb-2" />
            <span className="self-end text-center text-rf-gray-700 text-xxs w-full">
              {targetMovinDate}
            </span>
          </div>
        </div>
        <span className="text-center text-rf-gray-700 hidden md:block">
          {roommatePreference.isOpenToLivingAtNearbyAreaCommunity
            ? `Live anywhere in ${community.areaName}`
            : `Prefer to live at ${community.communityName}`}
        </span>
        <span className="text-center hidden md:block text-rf-gray-700">
          {budget}
          {lease}
        </span>
        <div className="p-2">
          <Button
            extraClass={`mx-auto block m-2 font-bold text-sm ${
              contacted && `cursor-default`
            } ${
              contacted
                ? `bg-white text-primary-green`
                : `bg-primary-green text-white`
            }`}
            onClick={!contacted ? onContactClick : () => {}}
          >
            {contacted ? `Contacted` : `Contact`}
          </Button>
        </div>
      </div>
      {isCurrentlyLiveAtCommunity && (
        <div className="bg-rf-gray-700 rounded-b text-center text-white">
          <span>{`Current ${community.communityName} Resident`}</span>
        </div>
      )}
    </div>
  );
}
