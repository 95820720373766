import React from "react";

import { AnyGenderIcon, CatsIcon, DogsIcon, FemaleGenderIcon, MaleGenderIcon, NoPetsIcon, NoSmokingIcon, PetsIcon, SmokingIcon } from "../utils/icons";

export function SmokingPreferenceIcon({ smoking }) {
  return smoking ? (
    <>
      <SmokingIcon className="w-8 h-8 md:w-12 md:h-12" />
      <span className="self-end text-center text-rf-gray-700 text-xxs w-full">Smoking OK</span>
    </>
  ) : (
    <>
      <NoSmokingIcon className="w-10 h-10 md:w-12 md:h-12" />
      <span className="self-end text-center text-rf-gray-700 text-xxs w-full">No Smoking</span>
    </>
  );
}

export function GenderPreferenceIcon({ gender }) {
  switch (gender) {
    case "Female":
      return (
        <>
          <FemaleGenderIcon className="w-8 h-8 md:w-10 md:h-10 mb-2" />
          <span className="self-end text-center text-rf-gray-700 text-xxs w-full">Female</span>
        </>
      );
    case "Male":
      return (
        <>
          <MaleGenderIcon className="w-8 h-8 md:w-10 md:h-10 mb-2" />
          <span className="self-end text-center text-rf-gray-700 text-xxs w-full">Male</span>
        </>
      );
    default:
      return (
        <>
          <AnyGenderIcon className="w-8 h-8 md:w-10 md:h-10 mb-2" />
          <span className="self-end text-center text-rf-gray-700 text-xxs w-full">Any</span>
        </>
      );
  }
}

export function PetsPreferenceIcon({ pets }) {
  if (pets.includes("None")) {
    return (
      <>
        <NoPetsIcon className="w-8 h-8 md:w-10 md:h-10 mb-2" />
        <span className="self-end text-center text-rf-gray-700 text-xxs w-full">No Pets</span>
      </>
    );
  }

  if (pets.length === 1) {
    if (pets.includes("Cat")) {
      return (
        <>
          <CatsIcon className="w-8 h-8 md:w-10 md:h-10 mb-2" />
          <span className="self-end text-center text-rf-gray-700 text-xxs w-full">Cats OK</span>
        </>
      );
    }

    if (pets.includes("Dog")) {
      return (
        <>
          <DogsIcon className="w-8 h-8 md:w-10 md:h-10 mb-2" />
          <span className="self-end text-center text-rf-gray-700 text-xxs w-full">Dogs OK</span>
        </>
      );
    }
  }

  return (
    <>
      <PetsIcon className="w-8 h-8 md:w-10 md:h-10 mb-2" />
      <span className="self-end text-center text-rf-gray-700 text-xxs w-full">Pets OK</span>
    </>
  );
}
