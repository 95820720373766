import React from "react";

import { LoadingSpinner } from "../utils/icons";

const Button = ({ children, extraClass, loading, ...rest }) => {
  return (
    <button
      className={`py-2 px-6 rounded uppercase flex focus:shadow-outline ${
        loading && `opacity-50 cursor-not-allowed`
      } ${extraClass}`}
      disabled={loading}
      {...rest}
    >
      {loading && <LoadingSpinner extraClass={`h-5 w-5 mr-2`} />}
      {children}
    </button>
  );
};

export default Button;
