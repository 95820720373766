import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form/dist/index.ie11";
import { Helmet } from "react-helmet";
import { Prompt } from "react-router-dom";

import InputWithLabel from "../components/forms/InputWithLabel";
import RadioButtons from "../components/forms/RadioButtons";
import Checkbox from "../components/forms/Checkbox";
import MultiCheckbox from "../components/forms/MultiCheckbox";
import SelectWithLabel from "../components/forms/SelectWithLabel";
import TextAreaWithLabel from "../components/forms/TextAreaWithLabel";
import Button from "../components/Button";
import UploadImageModal from "../components/modals/UploadImageModal";
import ProfileBanner from "../components/ProfileBanner";
import ResourcesLinks from "../components/ResourcesLinks";

import { useAuth } from "../utils/hooks/use-auth";

import {
  updateUser,
  mapProfileToUser,
  deleteUser,
} from "../services/user-service";
import {
  genderPreferences,
  genderValues,
  leaseValues,
  petValues,
  profileValues,
  rentValues,
  yesNoValues,
} from "../utils/consts";

import profileImgSrc from "../images/profile-large.png";
import Cookies from "js-cookie";

export default function Profile() {
  const auth = useAuth();

  const {
    handleSubmit,
    register,
    setError,
    clearErrors,
    errors,
    control,
    formState,
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      ...auth.profile,
    },
  });

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isUploaImageModalOpen, setIsUploaImageModalOpen] = useState(false);
  const [profileSaved, setProfileSaved] = useState(false);
  const formRef = useRef(null);

  const onSubmit = async (values) => {
    setLoading(true);
    const user = mapProfileToUser(values);

    const response = await updateUser(user);
    if (response.status === 200) {
      const updatedUser = response.data.data;
      auth.updateProfile(updatedUser);

      formRef.current.scrollIntoView({ behavior: "smooth" });
      setProfileSaved(true);
    }

    setLoading(false);
  };

  const handleDeleteProfile = async () => {
    setDeleteLoading(true);
    const response = await deleteUser();
    if (response.status === 200) {
      Cookies.set("redirect", "/profile-deleted");
      await auth.signout();
    }
  };

  const { profile } = auth;
  const { isDirty, isSubmitSuccessful } = formState;

  return (
    <>
      <Prompt
        message="You have pending changes. Are you sure you want to leave this page?"
        when={isDirty && !isSubmitSuccessful}
      />
      <Helmet>
        <title>Your Roommate Profile | Roommate Finder</title>
      </Helmet>
      <form
        className="w-full flex flex-col container mx-auto md:my-10"
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
      >
        <ProfileBanner profile={auth.profile} saved={profileSaved} />
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/4">
            <div className="flex flex-row md:flex-col items-center justify-center py-10">
              <img
                className="md:mb-5 w-48 md:w-40 lg:w-48 h-48 md:h-40 lg:h-48 rounded-full border border-rf-gray-700"
                src={(auth.profile && auth.profile.photoUrl) || profileImgSrc}
                alt="Profile"
              />
              <div className="px-5">
                <h2 className="font-bold md:text-center md:text-xl text-left text-rf-gray-400 text-sm uppercase">
                  {auth.profile && auth.profile.firstName}
                </h2>
                <span
                  className="block italic md:text-center md:text-xs text-left text-xs cursor-pointer"
                  onClick={() => setIsUploaImageModalOpen(true)}
                >
                  Upload Image
                </span>
                <div className="mt-5">
                  <RadioButtons
                    values={profileValues}
                    id="isVisibleForAllUsers"
                    name="isVisibleForAllUsers"
                    label="Profile Visibility:"
                    value={auth.profile && auth.profile.isVisibleForAllUsers}
                    register={register}
                    errors={errors.isVisibleForAllUsers}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-3/4">
            <div className="w-full">
              <h2 className="text-rf-gray-400 text-xl font-bold px-10 md:hidden">
                About You
              </h2>
              <div className="rounded bg-rf-gray md:px-4 px-10 py-3 mt-3 md:my-0">
                <h2 className="text-rf-gray-400 text-xl font-bold hidden md:block">
                  About You
                </h2>
                <div className="flex flex-col flex-wrap md:flex-row md:pt-8">
                  <div className="mb-4 lg:w-1/3 lg:mb-0 md:pr-3 w-full">
                    <InputWithLabel
                      label="First Name*"
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      register={register({
                        required: "This field is required",
                      })}
                      errors={errors.firstName}
                    />
                    {auth.profile && auth.profile.emailConfirmed ? (
                      <>
                        <input type="hidden" name="email" ref={register} />
                        <InputWithLabel
                          label="Email Address*"
                          id="email"
                          type="email"
                          placeholder="Email"
                          name="emailDisabled"
                          disabled={auth.profile && auth.profile.emailConfirmed}
                          value={auth.profile && auth.profile.email}
                        />
                      </>
                    ) : (
                      <InputWithLabel
                        label="Email Address*"
                        id="email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        register={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        errors={errors.email}
                      />
                    )}
                    <InputWithLabel
                      label="Date of Birth*"
                      description="Only your age will be displayed."
                      id="birthdate"
                      name="birthdate"
                      type="date"
                      placeholder="Date of Birth"
                      control={control}
                      selected={auth.profile && auth.profile.birthdate}
                      register={register({
                        required: "This field is required",
                        validate: (value) => {
                          var birthdate = new Date(value);
                          var diff = (Date.now() - birthdate) / 31557600000;
                          return diff > 18 || "You need to be 18+ years old";
                        },
                      })}
                      errors={errors.birthdate}
                    />
                    <SelectWithLabel
                      label="Target Monthly Rent*"
                      id="monthlyRent"
                      name="monthlyRent"
                      values={rentValues}
                      register={register({
                        required: "This field is required",
                      })}
                      errors={errors.monthlyRent}
                      value={auth.profile && auth.profile.monthlyRent}
                    />
                  </div>
                  <div className="mb-4 lg:w-1/3 lg:mb-0 md:pr-3 w-full">
                    <TextAreaWithLabel
                      label="What do you want your future roommate to know about you?*"
                      id="overview"
                      name="overview"
                      placeholder="Not sure what to say? Give your future roommate a better feel of who you are. Topics such as your career, interests, and lifestyle."
                      extraWrapperClass="-mt-5 lg:-mt-3"
                      register={register({
                        required: "This field is required",
                        maxLength: 250,
                      })}
                      errors={errors.overview}
                      limit={250}
                      initialCount={profile?.overview?.length}
                      setError={setError}
                      clearErrors={clearErrors}
                    />
                    <SelectWithLabel
                      label="Gender*"
                      id="gender"
                      values={genderValues}
                      name="gender"
                      register={register({
                        required: "This field is required",
                      })}
                      errors={errors.gender}
                      value={auth.profile && auth.profile.gender}
                    />
                    <InputWithLabel
                      label="Target Move-In Date*"
                      id="moveInDate"
                      type="date"
                      name="moveInDate"
                      control={control}
                      register={register({
                        required: "This field is required",
                        validate: (value) =>
                          new Date(value) > Date.now() ||
                          "Target Move-In Date needs to be greater than today.",
                      })}
                      selected={auth.profile && auth.profile.moveInDate}
                      errors={errors.moveInDate}
                    />
                  </div>
                  <div className="lg:w-1/3 lg:mb-0 w-full">
                    <SelectWithLabel
                      label="Lease Length*"
                      id="leaseLength"
                      values={leaseValues}
                      name="leaseLength"
                      register={register({
                        required: "This field is required",
                      })}
                      errors={errors.leaseLength}
                      value={auth.profile && auth.profile.leaseLength}
                    />
                    <MultiCheckbox
                      values={petValues}
                      label="Pet Owner*"
                      id="pets"
                      name="pets"
                      register={register({
                        required: "This field is required",
                      })}
                      errors={errors.pets}
                      value={auth.profile && auth.profile.pets}
                      single="None"
                    />
                    <Checkbox
                      values={yesNoValues}
                      label={`I Currently Live At ${profile?.community?.communityName}*`}
                      id="currentLivesAtCommunity"
                      name="currentLivesAtCommunity"
                      register={register({
                        required: "This field is required",
                      })}
                      errors={errors.currentLivesAtCommunity}
                      value={
                        auth.profile && auth.profile.currentLivesAtCommunity
                      }
                      extraMargin="lg:mt-6"
                    />
                    <Checkbox
                      values={yesNoValues}
                      label="Are You a Smoker?*"
                      id="smoker"
                      name="smoker"
                      register={register({
                        required: "This field is required",
                      })}
                      errors={errors.smoker}
                      value={auth.profile && auth.profile.smoker}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-6 ml-4 mt-2">
                <span className="text-sm text-rf-gray-900">
                  *All fields are required
                </span>
              </div>
              <h2 className="text-rf-gray-400 text-xl font-bold px-10 md:hidden">
                About Your Roommate
              </h2>
              <div className="rounded bg-rf-gray md:px-4 px-10 py-3 mb:mb-10 mt-3">
                <h2 className="text-rf-gray-400 text-xl font-bold hidden md:block">
                  About Your Roommate
                </h2>
                <div className="flex flex-col flex-wrap md:flex-row md:pt-4">
                  <div className="w-full lg:w-1/2 md:pr-3">
                    <Checkbox
                      values={yesNoValues}
                      label="Is Smoking Okay?*"
                      id="smokingPreference"
                      description="Most UDR communities are smoke-free."
                      name="smokingPreference"
                      register={register({
                        required: "This field is required",
                      })}
                      errors={errors.smokingPreference}
                      value={auth.profile && auth.profile.smokingPreference}
                    />
                    <MultiCheckbox
                      values={petValues}
                      label="Are Pets Okay?*"
                      id="petsPreference"
                      name="petsPreference"
                      register={register({
                        required: "This field is required",
                      })}
                      errors={errors.petsPreference}
                      value={auth.profile && auth.profile.petsPreference}
                      single="None"
                    />
                  </div>
                  <div className="w-full lg:w-1/2 md:pr-3">
                    <Checkbox
                      values={yesNoValues}
                      label={`Are You Open to Living at a Nearby ${profile?.community?.areaName} Community?*`}
                      id="openToLiveNearby"
                      name="openToLiveNearby"
                      register={register({
                        required: "This field is required",
                      })}
                      errors={errors.openToLiveNearby}
                      value={auth.profile && auth.profile.openToLiveNearby}
                    />
                    <Checkbox
                      values={genderPreferences}
                      id="genderPreference"
                      label="Gender Preference*"
                      name="genderPreference"
                      value={auth.profile && auth.profile.genderPreference}
                      register={register({
                        required: "This field is required",
                      })}
                      errors={errors.genderPreference}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:mb-10 my-5">
                {profile && !profile.completed && profile.community?.pages && (
                  <div
                    className={`mb-2 ${
                      errors.termsAndConditionsCheck
                        ? `text-red-500`
                        : `text-rf-gray-700`
                    }`}
                  >
                    <input
                      type="checkbox"
                      id="termsAndConditionsCheck"
                      name="termsAndConditionsCheck"
                      ref={register({
                        required: true,
                      })}
                    />{" "}
                    <label htmlFor="termsAndConditionsCheck">
                      I agree to{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline cursor-pointer"
                        href={profile.community.pages.termsAndConditions}
                      >
                        Terms and Conditions
                      </a>
                      .<span className="font-bold">*</span>
                    </label>
                  </div>
                )}
                <div className="flex items-center justify-center md:justify-start">
                  <Button
                    extraClass="mr-5 border border-rf-gray-100 bg-primary-green text-white text-sm"
                    type="submit"
                    loading={loading}
                  >
                    {loading ? "Saving Profile" : "Save Profile"}
                  </Button>
                  <Button
                    extraClass="text-white border border-rf-gray-100 bg-primary-red text-sm"
                    onClick={handleDeleteProfile}
                    loading={deleteLoading}
                    type="button"
                  >
                    {deleteLoading ? "Deleting Profile" : "Delete Profile"}
                  </Button>
                </div>
              </div>
              {profile.community?.pages && (
                <ResourcesLinks community={profile.community} />
              )}
            </div>
          </div>
        </div>
      </form>
      {isUploaImageModalOpen && (
        <UploadImageModal
          isOpen={isUploaImageModalOpen}
          closeModal={() => setIsUploaImageModalOpen(false)}
        />
      )}
    </>
  );
}
