import React from "react";
import { Link } from "react-router-dom";

import PageNotFoundSrc from "../images/404.png";

export default function NotFound({ children }) {
  return (
    <div className="flex flex-col items-center justify-items-center m-4">
      <img src={PageNotFoundSrc} alt="Page Not Found" />
      <p className="mt-4 mb-8 text-base md:text-xl text-rf-gray-900 max-w-md text-center">
        The page you are looking for may have moved, had its name changed, or does not exist.
      </p>
      <Link to="/" className="italic cursor-pointer font-bold text-base md:text-xl">
        Please click here to go to the homepage.
      </Link>
    </div>
  );
}
