import axios from "axios";
import { API_BASE_URL } from "../utils/consts";

export default async function apiCall({
  endpoint,
  method,
  data,
  headers,
  responseErrorHandler,
  requestErrorHandler,
  defaultErrorHandler,
}) {
  try {
    var response = await axios({
      method: method,
      url: `${API_BASE_URL}${endpoint}`,
      data: data,
      headers: headers,
    });

    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (responseErrorHandler && typeof responseErrorHandler === "function") {
        responseErrorHandler(error.response);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      if (requestErrorHandler && typeof requestErrorHandler === "function") {
        requestErrorHandler(error.request);
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      if (defaultErrorHandler && typeof defaultErrorHandler === "function") {
        defaultErrorHandler(error.message);
      }
    }
    
    return error;
  }
}
