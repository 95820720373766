import React from "react";

export default function Modal({ isOpen, closeModal, children, ...rest }) {
  return (
    <>
      <div
        className={`modal fixed w-full h-full top-0 left-0 flex items-center z-20 p-4 md:p-8 xl:p-0 ${
          !isOpen && `opacity-0 pointer-events-none`
        }`}
        {...rest}
      >
        <div
          className="modal-overlay absolute w-full h-full top-0 left-0 bg-black opacity-50"
          onClick={closeModal}
        ></div>
        {children}
      </div>
    </>
  );
}
